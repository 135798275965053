var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loading.status
    ? _c(
        "v-sheet",
        {
          staticClass: "d-flex align-center justify-center",
          attrs: { height: "200" },
        },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", color: "primary" },
          }),
        ],
        1
      )
    : _c(
        "v-container",
        { class: _vm.classes.container },
        [
          _vm.pages > 0 && false
            ? _c(
                "v-row",
                {
                  class: _vm.screenWidth < 600 ? `pb-4` : `pb-8`,
                  attrs: { id: "search", "no-gutters": "" },
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: _vm.cols.search.left } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.nameSearch,
                            expression: "nameSearch",
                          },
                        ],
                        staticClass: "search-style rounded rounded-r-0",
                        attrs: {
                          type: "text",
                          placeholder:
                            "Search by student, course, package name",
                        },
                        domProps: { value: _vm.nameSearch },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.nameSearch = $event.target.value
                          },
                        },
                      }),
                      _c(
                        "v-btn",
                        {
                          staticClass: "elevation-0 rounded-l-0",
                          attrs: {
                            color: "primary ",
                            "min-width": "20",
                            width: _vm.sizes.buttonSearch.width,
                            height: _vm.sizes.buttonSearch.height,
                          },
                        },
                        [
                          _c("v-icon", { attrs: { color: "white" } }, [
                            _vm._v("search"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  !(_vm.screenWidth < 600)
                    ? _c("v-col", { attrs: { cols: _vm.cols.search.middle } })
                    : _vm._e(),
                  !(_vm.screenWidth < 600)
                    ? _c(
                        "v-col",
                        {
                          staticClass:
                            "d-flex align-center justify-space-between",
                          attrs: { cols: _vm.cols.search.right },
                        },
                        [
                          _c("v-menu", {
                            attrs: { "offset-y": "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "input",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.courseFilter,
                                                  expression: "courseFilter",
                                                },
                                              ],
                                              staticClass: "input-style",
                                              style: `width: ${_vm.sizes.filterBar.width}`,
                                              attrs: {
                                                readonly: "",
                                                type: "text",
                                                placeholder: "Courses/Packages",
                                              },
                                              domProps: {
                                                value: _vm.courseFilter,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.courseFilter =
                                                    $event.target.value
                                                },
                                              },
                                            },
                                            "input",
                                            attrs,
                                            false
                                          ),
                                          on
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3055309925
                            ),
                          }),
                          _c("v-menu", {
                            attrs: { "offset-y": "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "input",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.batchFilter,
                                                  expression: "batchFilter",
                                                },
                                              ],
                                              staticClass: "input-style",
                                              style: `width: ${_vm.sizes.filterBar.width}`,
                                              attrs: {
                                                readonly: "",
                                                type: "text",
                                                placeholder: "Batches",
                                              },
                                              domProps: {
                                                value: _vm.batchFilter,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.batchFilter =
                                                    $event.target.value
                                                },
                                              },
                                            },
                                            "input",
                                            attrs,
                                            false
                                          ),
                                          on
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2581487431
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.pages > 0
            ? _c(
                "v-row",
                {
                  staticClass:
                    "rounded border border-solid border-default ft-bold xl:text-sm lg:text-sm md:text-xs sm:text-xs",
                  class: _vm.classes.rowHeader,
                  staticStyle: { color: "#808080" },
                  attrs: { id: "table-heading", "no-gutters": "" },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex align-center",
                      attrs: { cols: _vm.cols.table.content.name },
                    },
                    [_vm._v("Student Name")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex align-center",
                      attrs: { cols: _vm.cols.table.content.amount },
                    },
                    [_vm._v("Amount")]
                  ),
                  !(_vm.screenWidth < 600)
                    ? _c(
                        "v-col",
                        {
                          staticClass: "d-flex align-center justify-center",
                          attrs: { cols: "3" },
                        },
                        [_vm._v("Method")]
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex align-center justify-center",
                      attrs: { cols: _vm.cols.table.content.date },
                    },
                    [_vm._v("Date")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._l(_vm.transactions, function (tr, index) {
            return _c(
              "v-row",
              {
                key: index,
                staticClass:
                  "ft-medium xl:text-base lg:text-sm md:text-sm sm:text-xxs",
                class: _vm.classes.rowEntry,
                attrs: { "no-gutters": "" },
              },
              [
                _c(
                  "v-col",
                  {
                    staticClass: "d-flex align-center",
                    attrs: { cols: _vm.cols.table.content.name },
                  },
                  [
                    _c("div", { staticClass: "text-truncate" }, [
                      _vm._v(_vm._s(tr.initiator_name)),
                    ]),
                  ]
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "d-flex align-center",
                    attrs: { cols: _vm.cols.table.content.amount },
                  },
                  [
                    _c("span", [
                      _c("span", [
                        _vm._v(_vm._s(_vm.currencySymbol[tr.currency])),
                      ]),
                      _vm._v(" " + _vm._s(_vm.numberFormat(tr.amount))),
                    ]),
                  ]
                ),
                !(_vm.screenWidth < 600)
                  ? _c(
                      "v-col",
                      {
                        staticClass: "d-flex align-center justify-center",
                        attrs: { cols: "3" },
                      },
                      [
                        !(
                          tr.payment_method === undefined ||
                          _vm.icons[tr.payment_method.toUpperCase()] ===
                            undefined
                        )
                          ? _c("img", {
                              style: `width: ${_vm.sizes.methodIcon.width}px;`,
                              attrs: {
                                title: tr.payment_method,
                                src: _vm.icons[tr.payment_method.toUpperCase()],
                                alt: "method-img",
                              },
                            })
                          : !(tr.payment_method === undefined) &&
                            _vm.icons[tr.payment_method.toUpperCase()] ===
                              undefined
                          ? _c("span", [
                              _vm._v(_vm._s(tr.payment_method.toLowerCase())),
                            ])
                          : _c("span", [_vm._v("Bypassed")]),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "v-col",
                  {
                    staticClass: "d-flex align-center justify-center",
                    attrs: { cols: _vm.cols.table.content.date },
                  },
                  [_vm._v(_vm._s(_vm.dateFormat(tr.transaction_time)))]
                ),
              ],
              1
            )
          }),
          _vm.pages == 0
            ? _c("no-content-component", {
                attrs: {
                  height: _vm.sizes.noItemBox.height,
                  message: "You have no student payments",
                },
              })
            : _vm._e(),
          _vm.pages > 0
            ? _c(
                "div",
                [
                  !(_vm.screenWidth < 600)
                    ? _c("v-pagination", {
                        class: _vm.classes.pagination,
                        attrs: {
                          length: _vm.pages,
                          "total-visible": _vm.sizes.paginator.totalVisible,
                        },
                        model: {
                          value: _vm.page,
                          callback: function ($$v) {
                            _vm.page = $$v
                          },
                          expression: "page",
                        },
                      })
                    : _vm._e(),
                  _vm.screenWidth < 600
                    ? _c(
                        "v-row",
                        { staticClass: "pt-5", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex align-center justify-end",
                              attrs: { cols: "7" },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "ft-bold sm:text-sm" },
                                [_vm._v("Page: ")]
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex align-center justify-end",
                              attrs: { cols: "5" },
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { width: "75%" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "",
                                      "no-data-text": "No Paid students",
                                      items: _vm.pageItems,
                                    },
                                    model: {
                                      value: _vm.page,
                                      callback: function ($$v) {
                                        _vm.page = $$v
                                      },
                                      expression: "page",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
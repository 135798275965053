var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.renderNow
    ? _c(
        "v-sheet",
        { class: _vm.classes.sheet, staticStyle: { width: "100%" } },
        [
          _vm._l(_vm.transactions, function (tr, idx) {
            return _c(
              "div",
              {
                key: idx,
                staticClass: "rounded border border-solid border-default",
                class: _vm.classes.card,
                staticStyle: { "background-color": "#f5f5f5" },
                style: _vm.styles.card,
              },
              [
                _c(
                  "v-row",
                  {
                    staticStyle: { height: "100%" },
                    attrs: { "no-gutters": "" },
                  },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "d-flex align-center",
                        attrs: { cols: _vm.cols.info.left },
                      },
                      [
                        !(_vm.screenWidth < 600)
                          ? _c("img", {
                              style: `width: ${_vm.sizes.cardIcon.width}px;`,
                              attrs: {
                                src: _vm.icons.card,
                                alt: "transaction-image",
                              },
                            })
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "d-flex flex-column",
                            class: _vm.classes.cardHeader,
                            staticStyle: { width: "75%" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-truncate ft-bold xl:text-base lg:text-base md:text-sm sm:text-sm",
                                attrs: { title: tr.title },
                              },
                              [_vm._v(" " + _vm._s(tr.title) + " ")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "ft-light xl:text-xs lg:text-xs md:text-xxs sm:text-xxs",
                              },
                              [
                                _vm._v(
                                  " Date: " +
                                    _vm._s(
                                      _vm.dateFormat(tr.transaction_time)
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "d-flex align-center",
                        attrs: { cols: _vm.cols.info.right },
                      },
                      [
                        _c(
                          "v-row",
                          { attrs: { "no-gutters": "" } },
                          [
                            _vm.vendorCondition
                              ? _c(
                                  "v-col",
                                  {
                                    staticClass: "d-flex flex-column",
                                    attrs: { cols: "7" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ft-light xl:text-xs lg:text-xs md:text-xxs sm:text-xxs",
                                      },
                                      [
                                        _vm._v(
                                          " Method: " +
                                            _vm._s(
                                              tr.payment_method || "Bypassed"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ft-light xl:text-xs lg:text-xs md:text-xxs sm:text-xxs mt-2 text-truncate",
                                        style: `width: ${_vm.sizes.sessionId.width}`,
                                      },
                                      [
                                        _vm._v(
                                          " Transaction ID: " +
                                            _vm._s(
                                              tr.payment_method_session_id ||
                                                "NA"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "v-col",
                              {
                                class: `d-flex align-center ${
                                  _vm.screenWidth > 960
                                    ? "justify-space-between"
                                    : "justify-end"
                                }`,
                                attrs: {
                                  cols: _vm.screenWidth > 960 ? "5" : "12",
                                },
                              },
                              [
                                _c(
                                  "v-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "d-flex justify-end",
                                        attrs: { cols: "9" },
                                      },
                                      [
                                        !(_vm.screenWidth < 600) &&
                                        _vm.wasComplete(tr)
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "xl:text-2xl lg:text-2xl md:text-base sm:text-base primary--text d-flex align-center",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.numberFormat(tr.amount)
                                                  ) +
                                                    _vm._s(
                                                      _vm.currencySymbol[
                                                        tr.currency
                                                      ]
                                                    )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        !_vm.wasComplete(tr)
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "xl:text-xl lg:text-xl md:text-base sm:text-sm decline--text d-flex align-center",
                                                class: {
                                                  "mr-2": _vm.screenWidth < 600,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      tr.status === "aborted"
                                                        ? "Aborted"
                                                        : "Failed"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "d-flex justify-end",
                                        attrs: { cols: "3" },
                                      },
                                      [
                                        _c(
                                          "v-menu",
                                          {
                                            attrs: { "offset-y": "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on, attrs }) {
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              style:
                                                                _vm.styles
                                                                  .button,
                                                              attrs: {
                                                                icon: "",
                                                              },
                                                            },
                                                            "v-btn",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _c("img", {
                                                            style: `height: ${_vm.sizes.menuIcon.height}px;`,
                                                            attrs: {
                                                              alt: "menu-image",
                                                              src: _vm.icons
                                                                .menu,
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c(
                                              "v-list",
                                              {
                                                staticClass:
                                                  "elevation-0 rounded border-solid border-default border-1-2 py-0",
                                              },
                                              [
                                                _c(
                                                  "v-list-item",
                                                  {
                                                    staticClass:
                                                      "px-3 xl:text-base lg:text-base md:text-sm sm:text-xs",
                                                    class: {
                                                      inert:
                                                        !tr.payment_method_session_id,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.copySessionId(
                                                          tr.payment_method_session_id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        class: {
                                                          "grey--text":
                                                            !tr.payment_method_session_id,
                                                        },
                                                      },
                                                      [_vm._v("Copy ID")]
                                                    ),
                                                  ]
                                                ),
                                                _c("v-divider"),
                                                _c(
                                                  "v-list-item",
                                                  {
                                                    staticClass:
                                                      "px-3 xl:text-base lg:text-base md:text-sm sm:text-xs",
                                                    class: {
                                                      inert:
                                                        !_vm.wasComplete(tr),
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.showReceipt(
                                                          tr.transaction_id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        class: {
                                                          "grey--text":
                                                            !_vm.wasComplete(
                                                              tr
                                                            ),
                                                        },
                                                      },
                                                      [_vm._v("View Receipt")]
                                                    ),
                                                  ]
                                                ),
                                                _c("v-divider"),
                                                _c(
                                                  "v-list-item",
                                                  {
                                                    staticClass:
                                                      "px-3 xl:text-base lg:text-base md:text-sm sm:text-xs",
                                                    class: {
                                                      inert:
                                                        !_vm.wasComplete(tr),
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.downloadReceipt(
                                                          tr.transaction_id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        class: {
                                                          "grey--text":
                                                            !_vm.wasComplete(
                                                              tr
                                                            ),
                                                        },
                                                      },
                                                      [_vm._v("Download")]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    !_vm.vendorCondition
                      ? _c(
                          "v-col",
                          { class: _vm.classes.vendor, attrs: { cols: "12" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "ft-light xl:text-xs lg:text-xs md:text-xxs sm:text-xxs",
                                class: _vm.screenWidth < 600 ? "" : "pl-9",
                                style: `width: ${_vm.sizes.fakeSize}`,
                              },
                              [
                                _vm._v(
                                  " Method: " +
                                    _vm._s(tr.payment_method || "N/A") +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "ft-light xl:text-xs lg:text-xs md:text-xxs sm:text-xxs text-truncate",
                                class: _vm.screenWidth < 600 ? "" : "pl-9",
                                style: `width: ${_vm.sizes.sessionId.width}`,
                              },
                              [
                                _vm._v(
                                  " Transaction ID: " +
                                    _vm._s(
                                      tr.payment_method_session_id || "N/A"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _vm.pages == 0
            ? _c("no-content-component", {
                attrs: {
                  message: "You have no transaction history",
                  height: _vm.sizes.noItemBox.height,
                },
              })
            : _vm._e(),
          _vm.pages > 0
            ? _c(
                "div",
                { staticClass: "pt-5" },
                [
                  _vm.screenWidth >= 600
                    ? _c("v-pagination", {
                        attrs: {
                          length: _vm.pages,
                          "total-visible": _vm.sizes.paginator.totalVisible,
                        },
                        model: {
                          value: _vm.page,
                          callback: function ($$v) {
                            _vm.page = $$v
                          },
                          expression: "page",
                        },
                      })
                    : _vm._e(),
                  _vm.screenWidth < 600
                    ? _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex align-center justify-end",
                              attrs: { cols: "7" },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "ft-bold sm:text-sm" },
                                [_vm._v("Page: ")]
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex align-center justify-end",
                              attrs: { cols: "5" },
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { width: "75%" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "",
                                      "no-data-text": "No Transactions",
                                      items: _vm.pageItems,
                                    },
                                    model: {
                                      value: _vm.page,
                                      callback: function ($$v) {
                                        _vm.page = $$v
                                      },
                                      expression: "page",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-dialog",
            {
              staticClass: "rounded",
              attrs: { width: "75%" },
              model: {
                value: _vm.showReceiptMenu,
                callback: function ($$v) {
                  _vm.showReceiptMenu = $$v
                },
                expression: "showReceiptMenu",
              },
            },
            [_c("receipt-dialog", { attrs: { contents: _vm.receiptContent } })],
            1
          ),
        ],
        2
      )
    : _c(
        "v-sheet",
        {
          class: [_vm.classes.sheet, "d-flex align-center justify-center"],
          attrs: { height: "200" },
        },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", color: "primary" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { class: ["main", _vm.classes.main], attrs: { id: "main" } },
    [
      _c("h2", { class: _vm.classes.header, attrs: { id: "header" } }, [
        _vm._v("Payments & Transactions"),
      ]),
      !(_vm.screenWidth < 600)
        ? _c(
            "v-tabs",
            {
              staticClass: "tab-bar",
              attrs: { "hide-slider": "", "background-color": "transparent" },
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            _vm._l(_vm.tabItems, function (tab) {
              return _c(
                "v-tab",
                {
                  key: tab,
                  class: _vm.classes.tab,
                  attrs: { "active-class": "selected-tab", darks: "" },
                },
                [_vm._v(" " + _vm._s(tab) + " ")]
              )
            }),
            1
          )
        : _c(
            "v-expansion-panels",
            {
              model: {
                value: _vm.expTabOpen,
                callback: function ($$v) {
                  _vm.expTabOpen = $$v
                },
                expression: "expTabOpen",
              },
            },
            [
              _c(
                "v-expansion-panel",
                { staticClass: "mb-5" },
                [
                  _c("v-expansion-panel-header", [
                    _c("span", { staticClass: "sm:text-base" }, [
                      _vm._v(_vm._s(_vm.tabItems[_vm.tab])),
                    ]),
                  ]),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c(
                        "v-list",
                        _vm._l(_vm.tabItems, function (item, index) {
                          return _c(
                            "v-list-item",
                            {
                              key: index,
                              staticClass: "sm:text-sm",
                              on: {
                                click: function ($event) {
                                  return _vm.changeTab(index)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(item) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _c("hr", { class: _vm.classes.hr }),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c(
            "v-tab-item",
            { staticStyle: { "background-color": "#f3f7ff" } },
            [_c("subscription-component")],
            1
          ),
          _c(
            "v-tab-item",
            { staticStyle: { "background-color": "#f3f7ff" } },
            [_c("transaction-component")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
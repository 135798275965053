var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.renderNow
        ? _c(
            "v-sheet",
            { class: _vm.classes.sheet, staticStyle: { width: "100%" } },
            [
              _c("v-row", { attrs: { "no-gutters": "" } }, [
                _c(
                  "span",
                  {
                    staticClass:
                      "xl:text-sm lg:text-sm md:text-xs sm:text-xxs grey--text inert",
                  },
                  [
                    _vm._v(
                      "In this package you will be charged " +
                        _vm._s(_vm.packageInfo.currency.toUpperCase()) +
                        " " +
                        _vm._s(_vm.packageInfo.price) +
                        "/per SMS"
                    ),
                  ]
                ),
              ]),
              _c(
                "v-row",
                {
                  class: [
                    "mt-6 border border-solid border-default rounded",
                    _vm.classes.header.padding,
                  ],
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "v-col",
                    {
                      class: [
                        "d-flex align-center justify-center",
                        _vm.classes.header.text,
                      ],
                      attrs: { cols: _vm.cols.content.left },
                    },
                    [_vm._v("Balance")]
                  ),
                  _c(
                    "v-col",
                    {
                      class: [
                        "d-flex align-center justify-center",
                        _vm.classes.header.text,
                      ],
                      attrs: { cols: _vm.cols.content.mid },
                    },
                    [_vm._v("Last Purchase")]
                  ),
                  !(_vm.screenWidth < 600)
                    ? _c(
                        "v-col",
                        {
                          class: [
                            "d-flex align-center justify-center",
                            _vm.classes.header.text,
                          ],
                        },
                        [_vm._v(" Actions ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-4", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      class: [
                        "d-flex align-center justify-center",
                        _vm.classes.header.text,
                      ],
                      attrs: { cols: _vm.cols.content.left },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.outgoing.messages,
                            expression: "outgoing.messages",
                          },
                        ],
                        staticClass:
                          "inert input-style border border-solid border-default rounded xl:text-base",
                        attrs: { type: "text" },
                        domProps: { value: _vm.outgoing.messages },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.outgoing,
                              "messages",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      class: [
                        "d-flex align-center justify-center",
                        _vm.classes.header.text,
                      ],
                      attrs: { cols: _vm.cols.content.mid },
                    },
                    [_vm._v(_vm._s(_vm.lastPayment))]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex align-center justify-center",
                      class: _vm.classes.button,
                      attrs: { cols: _vm.cols.content.right },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: {
                            block: _vm.screenWidth < 600,
                            depressed: "",
                            color: "#0099dc",
                            small: _vm.smallActionButton,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.goToSubPurchase(true)
                            },
                          },
                        },
                        [_vm._v("Buy New SMS")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-row", {
                class: _vm.classes.filler,
                attrs: { "no-gutters": "" },
              }),
            ],
            1
          )
        : _c(
            "v-sheet",
            {
              class: [_vm.classes.sheet, "d-flex align-center justify-center"],
              staticStyle: { width: "100%" },
            },
            [
              _vm.networkError
                ? _c("no-content-component", {
                    attrs: { message: "Data Unavailable" },
                  })
                : _c("v-progress-circular", {
                    attrs: { color: "primary", indeterminate: "" },
                  }),
            ],
            1
          ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showMessageHistoryCondition,
              expression: "showMessageHistoryCondition",
            },
          ],
          staticClass: "mt-5",
        },
        [
          _c(
            "v-sheet",
            { staticClass: "mt-10", class: _vm.classes.sheet },
            [
              _c("v-row", { attrs: { "no-gutters": "" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "ft-bold xl:text-lg lg:text-lg md:text-base sm:text-sm",
                  },
                  [_vm._v(" History ")]
                ),
              ]),
              _c(
                "v-row",
                {
                  class: [
                    "mt-6 border border-solid border-default rounded",
                    _vm.classes.header.padding,
                  ],
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "v-col",
                    {
                      class: [
                        "d-flex align-center justify-start",
                        _vm.classes.header.text,
                        _vm.classes.header.colPadding,
                      ],
                      attrs: { cols: "4" },
                    },
                    [_vm._v("Title")]
                  ),
                  _c(
                    "v-col",
                    {
                      class: [
                        "d-flex align-center justify-center",
                        _vm.classes.header.text,
                      ],
                      attrs: { cols: "3" },
                    },
                    [_vm._v("Date")]
                  ),
                  _c(
                    "v-col",
                    {
                      class: [
                        "d-flex align-center justify-center",
                        _vm.classes.header.text,
                      ],
                      attrs: { cols: "3" },
                    },
                    [_vm._v(" Price ")]
                  ),
                  _c(
                    "v-col",
                    {
                      class: [
                        "d-flex align-center justify-center",
                        _vm.classes.header.text,
                      ],
                      attrs: { cols: "2" },
                    },
                    [_vm._v("Status")]
                  ),
                ],
                1
              ),
              _vm._l(_vm.subscriptionHistory.transactions, function (subH, i) {
                return _c(
                  "div",
                  { key: i },
                  [
                    _c(
                      "v-row",
                      { staticClass: "pa-2", attrs: { "no-gutters": "" } },
                      [
                        _c(
                          "v-col",
                          {
                            class: [
                              "d-flex align-center justify-start",
                              _vm.classes.header.text,
                              _vm.classes.colPadding,
                            ],
                            attrs: { cols: "4" },
                          },
                          [
                            _vm._v(
                              " Payment for " +
                                _vm._s(subH["num_of_sms"]) +
                                " sms "
                            ),
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            class: [
                              "d-flex align-center justify-center",
                              _vm.classes.header.text,
                            ],
                            attrs: { cols: "3" },
                          },
                          [_vm._v(" " + _vm._s(subH["date"]) + " ")]
                        ),
                        _c(
                          "v-col",
                          {
                            class: [
                              "d-flex align-center justify-center",
                              _vm.classes.header.text,
                            ],
                            attrs: { cols: "3" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.currencySymbol[_vm.packageInfo.currency]
                                ) +
                                _vm._s(subH["price"]) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            class: [
                              "d-flex pl-3 align-center justify-center",
                              _vm.classes.header.text,
                            ],
                            attrs: { cols: "2" },
                          },
                          [_vm._v(" " + _vm._s(subH["status"]) + " ")]
                        ),
                      ],
                      1
                    ),
                    _c("v-divider", { staticClass: "border-opacity-85" }),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
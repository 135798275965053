<template>
  <v-sheet v-if="loading.status" height="200" class="d-flex align-center justify-center">
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </v-sheet>
  <v-container v-else :class="classes.container">
    <v-row
      v-if="pages > 0 && false"
      id="search"
      no-gutters
      :class="screenWidth < 600 ? `pb-4` : `pb-8`"
    >
      <v-col :cols="cols.search.left">
        <input
          v-model="nameSearch"
          class="search-style rounded rounded-r-0"
          type="text"
          placeholder="Search by student, course, package name"
        />
        <v-btn
          class="elevation-0 rounded-l-0"
          color="primary "
          min-width="20"
          :width="sizes.buttonSearch.width"
          :height="sizes.buttonSearch.height"
        >
          <v-icon color="white">search</v-icon>
        </v-btn>
      </v-col>
      <v-col v-if="!(screenWidth < 600)" :cols="cols.search.middle"></v-col>
      <v-col
        v-if="!(screenWidth < 600)"
        :cols="cols.search.right"
        class="d-flex align-center justify-space-between"
      >
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <input
              v-model="courseFilter"
              :style="`width: ${sizes.filterBar.width}`"
              readonly
              class="input-style"
              type="text"
              placeholder="Courses/Packages"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <!--a list of options goes here-->
        </v-menu>
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <input
              v-model="batchFilter"
              :style="`width: ${sizes.filterBar.width}`"
              readonly
              class="input-style"
              type="text"
              placeholder="Batches"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <!--a list of options goes here-->
        </v-menu>
      </v-col>
    </v-row>
    <v-row
      v-if="pages > 0"
      id="table-heading"
      no-gutters
      style="color: #808080"
      class="rounded border border-solid border-default ft-bold xl:text-sm lg:text-sm md:text-xs sm:text-xs"
      :class="classes.rowHeader"
    >
      <v-col :cols="cols.table.content.name" class="d-flex align-center"
        >Student Name</v-col
      >
      <v-col :cols="cols.table.content.amount" class="d-flex align-center"
        >Amount</v-col
      >
      <v-col
        v-if="!(screenWidth < 600)"
        cols="3"
        class="d-flex align-center justify-center"
        >Method</v-col
      >
      <v-col
        :cols="cols.table.content.date"
        class="d-flex align-center justify-center"
        >Date</v-col
      >
    </v-row>
    <v-row
      v-for="(tr, index) in transactions"
      :key="index"
      no-gutters
      class="ft-medium xl:text-base lg:text-sm md:text-sm sm:text-xxs"
      :class="classes.rowEntry"
    >
      <v-col :cols="cols.table.content.name" class="d-flex align-center"
        ><div class="text-truncate">{{ tr.initiator_name }}</div></v-col
      >
      <v-col :cols="cols.table.content.amount" class="d-flex align-center"
        ><span
          ><span>{{ currencySymbol[tr.currency] }}</span>
          {{ numberFormat(tr.amount) }}</span
        ></v-col
      >
      <v-col
        v-if="!(screenWidth < 600)"
        cols="3"
        class="d-flex align-center justify-center"
      >
        <img
          v-if="
            !(
              tr.payment_method === undefined ||
              icons[tr.payment_method.toUpperCase()] === undefined
            )
          "
          :title="tr.payment_method"
          :src="icons[tr.payment_method.toUpperCase()]"
          :style="`width: ${sizes.methodIcon.width}px;`"
          alt="method-img"
        />
        <span
          v-else-if="
            !(tr.payment_method === undefined) &&
            icons[tr.payment_method.toUpperCase()] === undefined
          "
          >{{ tr.payment_method.toLowerCase() }}</span
        >
        <span v-else>Bypassed</span>
      </v-col>
      <v-col
        :cols="cols.table.content.date"
        class="d-flex align-center justify-center"
        >{{ dateFormat(tr.transaction_time) }}</v-col
      >
    </v-row>
    <no-content-component
      v-if="pages == 0"
      :height="sizes.noItemBox.height"
      message="You have no student payments"
    ></no-content-component>
    <div v-if="pages > 0">
      <v-pagination
        v-if="!(screenWidth < 600)"
        v-model="page"
        :length="pages"
        :total-visible="sizes.paginator.totalVisible"
        :class="classes.pagination"
      ></v-pagination>
      <v-row v-if="screenWidth < 600" no-gutters class="pt-5">
        <v-col cols="7" class="d-flex align-center justify-end">
          <span class="ft-bold sm:text-sm">Page: </span>
        </v-col>
        <v-col cols="5" class="d-flex align-center justify-end">
          <div style="width: 75%">
            <v-select
              v-model="page"
              outlined
              dense
              hide-details
              no-data-text="No Paid students"
              :items="pageItems"
            ></v-select>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { getOwnCourses } from "@ecf/modules/courses/actions";
import NoContentComponent from "/global/components/NoContentComponent";
import { PaymentFetch } from "#ef/payment-transaction/mixins.js";

export default {
  name: "PaidStudentsTable",
  data() {
    return {
      nameSearch: "",
      paymentMethods: ["bKash", "Nagad", "Cash"],
      page: 1,
      courseFilter: undefined,
      batchFilter: undefined,
      loading: {},
    };
  },
  components: { NoContentComponent },
  mixins: [PaymentFetch],
  computed: {
    screenWidth() {
      return this.$vuetify.breakpoint.width;
    },
    classes() {
      return {
        container: `pa-${this.breakPointValues(3, 3, 5, 6)}`,
        rowHeader: `px-${this.breakPointValues(
          4,
          4,
          4,
          6
        )} py-${this.breakPointValues(2, 2, 2, 2)}`,
        rowEntry: `px-${this.breakPointValues(
          4,
          4,
          4,
          6
        )} py-${this.breakPointValues(
          5,
          5,
          3,
          5
        )} border-0 border-b border-solid border-default`,
        pagination: `pt-${this.breakPointValues(4, 4, 4, 5)}`,
      };
    },
    sizes() {
      return {
        methodIcon: {
          width: 45,
        },
        filterBar: {
          width: this.breakPointValues(
            "calc(50% - 10px)",
            "calc(50% - 10px)",
            "calc(50% - 15px)",
            "calc(50% - 20px)"
          ),
        },
        buttonSearch: {
          height: this.breakPointValues(30, 30, 35, 35),
          width: this.breakPointValues(30, 30, 35, 35),
        },
        paginator: {
          totalVisible: this.breakPointValues(2, 5, 7, 7),
        },
        noItemBox: {
          height: 200,
        },
      };
    },
    cols() {
      return {
        search: {
          left: this.breakPointValues("10", "5", "4", "4"),
          middle: this.breakPointValues("1", "1", "3", "3"),
          right: this.breakPointValues("6", "6", "5", "5"),
        },
        table: {
          content: {
            name: this.breakPointValues("5", "4", "4", "4"),
            amount: this.breakPointValues("3", "2", "2", "2"),
            date: this.breakPointValues("4", "3", "3", "3"),
          },
        },
      };
    },
    icons() {
      return {
        BKASH: require("#ecf/payment-transation/assets/icons/bkash.png"),
        NAGAD: require("#ecf/payment-transation/assets/icons/nagad.png"),
        CASH: require("#ecf/payment-transation/assets/icons/cash.png"),
      };
    },
    currencySymbol() {
      return {
        bdt: "৳",
        usd: "$",
      };
    },
    pages() {
      return this.$store.state.transaction.studentTransactionPages || 0;
    },
    transactions() {
      //return transaction for the current page
      let listItems = this.paidStudentTransactions;
      if (listItems === undefined) return [];
      else return listItems[this.page];
    },
    paidStudentTransactions() {
      return this.$store.getters["transaction/studentTransactions"];
    },
    pageItems() {
      let n = this.pages;
      return Array.from({ length: n }, (_, index) => index + 1);
    },
    // the following will be required when Transactions is moved to SQL
    // courseList(){
    //   return this.$store.getters['courses/myCourses'];
    // }
  },
  watch: {
    page: {
      async handler(value) {
        if (!(value in this.paidStudentTransactions)) {
          await this.bufferFetch(this.loading, async () => {
            await this.fetchStudentTransactions(value);
          });
        }
      },
    },
  },
  async created() {
    await this.bufferFetch(this.loading, async () => {
      await this.fetchStudentTransactionPages();
      await this.fetchStudentTransactions(this.page);
      await this.fetchCourseList();
    });
  },
  methods: {
    breakPointValues(smValue, mdValue, lgValue, xlValue) {
      let s = this.screenWidth;
      return s < 600
        ? smValue
        : s < 961
        ? mdValue
        : s < 1903
        ? lgValue
        : xlValue;
    },
    dateFormat(date) {
      let out = new Date(date);
      return out.toLocaleString("en-gb", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    },
    numberFormat(number) {
      return Number(number).toFixed(2);
    },
    async fetchStudentTransactionPages() {
      await this.$store.dispatch("transaction/pagesStuedentTransaction");
    },
    async fetchStudentTransactions(page) {
      await this.$store.dispatch("transaction/listStudentTransactions", {
        page,
      });
    },
    async fetchCourseList() {
      let temp = await getOwnCourses(this.$store);
      this.courseList = temp.map((v) => ({
        title: v.course_title,
        id: v.course_id,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
.search-style {
  height: 35px;
  width: calc(100% - 35px);
  padding: 0px 10px;
  border: 1px solid $border;
  @media screen and (max-width: 960px) {
    height: 30px;
  }
}
.search-style:focus {
  outline: none;
  @media screen and (max-width: 960px) {
    height: 28px;
  }
}

.input-style {
  height: 35px;
  padding: 0px 10px;
  border: 1px solid $border;
  border-radius: 3px;
  // outline: 2px solid white;
  @media screen and (max-width: 960px) {
    height: 30px;
  }
}
.input-style:focus {
  // outline: none;
  // border: 2px solid $primary;
  outline: 2px solid $primary;
  height: 33px;
  @media screen and (max-width: 960px) {
    height: 28px;
  }
}
</style>

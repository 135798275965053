<template>
  <div>
    <v-sheet
      v-if="showHistoryCondition"
      style="width: 100%"
      :class="classes.sheet"
      class="mb-5 pb-5"
    >
      <div class="ft-bold xl:text-lg lg:text-lg md:text-base sm:text-sm">
        Active Subscriptions
      </div>
      <v-row
        v-if="!(screenWidth < 600)"
        no-gutters
        :class="[
          'mt-6 border border-solid border-default rounded',
          classes.header.padding,
        ]"
      >
        <v-col
          :class="[
            'd-flex align-center justify-start',
            classes.header.text,
            classes.history.firstItem,
          ]"
          cols="5"
          >Title</v-col
        >
        <v-col
          :class="['d-flex align-center justify-center', classes.header.text]"
          cols="4"
          >Start Date</v-col
        >
        <v-col
          :class="['d-flex align-center justify-center', classes.header.text]"
          cols="3"
          >End Date</v-col
        >
      </v-row>
      <div
        id="hisotry-container"
        :style="`height: ${sizes.historyContainer.height}px`"
        class="overflow-x-hidden overflow-y"
      >
        <v-row
          v-for="(item, index) in historyContent"
          :key="index"
          no-gutters
          :class="[
            classes.history.padding,
            classes.history.border,
            classes.history.margin,
            { 'primary--text': index == 0 },
          ]"
        >
          <v-col
            class="d-flex align-center justify-start xl:text-sm lg:text-sm md:text-xs sm:text-xs"
            :cols="cols.history.title"
            :class="classes.history.firstItem"
            >Subscription for {{ item.num_students }} students</v-col
          >
          <v-col
            :class="[
              'd-flex align-center',
              screenWidth < 600 ? 'justify-start' : ' justify-center',
              classes.header.text,
              { 'py-2': screenWidth < 600 },
            ]"
            :cols="cols.history.start"
            ><span v-if="screenWidth < 600" class="pr-1">Start:</span
            >{{ dateFormat(item.from) }}</v-col
          >
          <v-col
            :class="[
              'd-flex align-center',
              screenWidth < 600 ? 'justify-start' : ' justify-center',
              classes.header.text,
              { 'pb-2': screenWidth < 600 },
            ]"
            :cols="cols.history.end"
            ><span v-if="screenWidth < 600" class="pr-1">End:</span
            >{{ dateFormat(item.to) }}</v-col
          >
        </v-row>
      </div>
    </v-sheet>
    <submission-alert-message
      ref="banner"
      v-model="showBanner"
      :message="bannerMessage"
      class="mb-4"
    ></submission-alert-message>
    <v-sheet v-if="renderNow" style="width: 100%" :class="classes.sheet">
      <v-row no-gutters>
        <span
          class="xl:text-sm lg:text-sm md:text-xs sm:text-xxs grey--text inert"
          >In this package you will getting all features unlocked. You will be
          charged {{ packageInfo.currency.toUpperCase() }}
          {{ packageInfo.price }}/per student in this package</span
        >
      </v-row>
      <v-row
        no-gutters
        :class="[
          'mt-6 border border-solid border-default rounded',
          classes.header.padding,
        ]"
      >
        <v-col :cols="cols.table.left">
          <v-row no-gutters>
            <v-col
              :class="[
                'd-flex align-center justify-center',
                classes.header.text,
              ]"
              cols=""
              >Total Students</v-col
            >
            <v-col
              v-if="screenWidth > 960"
              :class="[
                'd-flex align-center justify-center',
                classes.header.text,
              ]"
              cols=""
              >Unit Price</v-col
            >
            <v-col
              :class="[
                'd-flex align-center justify-center',
                classes.header.text,
              ]"
              cols=""
              >Total Paid</v-col
            >
            <v-col
              v-if="screenWidth >= 600"
              :class="[
                'd-flex align-center justify-center',
                classes.header.text,
              ]"
              cols=""
              >{{ headerNexPayment }}</v-col
            >
            <!-- <v-col
            v-if="screenWidth > 960"
            :class="['d-flex align-center justify-center', classes.header.text]"
            cols=""
            >Auto-renewal</v-col
          > -->
          </v-row>
        </v-col>
        <v-col
          v-if="!(screenWidth < 600)"
          :class="['d-flex align-center justify-center', classes.header.text]"
          :cols="cols.table.right"
        >
          Actions
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-4 d-flex align-center">
        <v-col :cols="cols.table.left">
          <v-row no-gutters>
            <v-col
              :class="[
                'd-flex align-center justify-center',
                classes.header.text,
              ]"
              cols=""
            >
              <input
                v-model="outgoing.students"
                type="text"
                class="input-style border border-solid border-default rounded xl:text-base"
                :class="{ 'primary--text': canUpdateCondition }"
              />
              <div class="input-spin-style ml-1">
                <div
                  class="input-spin-button-style border border-solid border-default rounded d-flex align-center justify-center"
                  @click="outgoing.students += 1"
                >
                  <v-icon size="12">mdi-plus</v-icon>
                </div>
                <div
                  class="input-spin-button-style border border-solid border-default rounded d-flex align-center justify-center"
                  @click="
                    outgoing.students = Math.max(0, outgoing.students - 1)
                  "
                >
                  <v-icon size="12">mdi-minus</v-icon>
                </div>
              </div>
            </v-col>
            <v-col
              v-if="screenWidth > 960"
              :class="[
                'd-flex align-center justify-center',
                classes.header.text,
              ]"
              cols=""
              >{{ currencySymbol[packageInfo.currency]
              }}{{ packageInfo.price }}/per student</v-col
            >
            <v-col
              :class="[
                'd-flex align-center justify-center',
                classes.header.text,
              ]"
              cols=""
              >{{ totalPrice }}</v-col
            >
            <v-col
              v-if="screenWidth >= 600"
              :class="[
                'd-flex align-center justify-center',
                classes.header.text,
                classes.nextPayment,
              ]"
              cols=""
              >{{ validTill }}</v-col
            >
            <!-- <v-col
            v-if="screenWidth > 960"
            class="d-flex align-center justify-center"
            cols=""
          >
            <v-menu v-model="showAutoRenewMenu">
              <template #activator="{ on, attrs }">
                <div
                  style="height: 100%; width: 100%"
                  class="d-flex align-center justify-center"
                  :class="{
                    'inert grey--text': packageInfo.autoRenewal === undefined,
                  }"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ outgoing.autoRenewal ? "on" : "off" }}
                  <v-icon :class="classes.renewalIcon">mdi-chevron-down</v-icon>
                </div>
              </template>
              <v-list>
                <v-list-item class="clickable" @click="setAutoRenew(true)"
                  >on</v-list-item
                >
                <v-list-item class="clickable" @click="setAutoRenew(false)"
                  >off</v-list-item
                >
              </v-list>
            </v-menu>
          </v-col> -->
          </v-row>
        </v-col>
        <v-col v-if="screenWidth >= 600" class="" :cols="cols.table.right">
          <v-row no-gutters>
            <v-col cols="6" class="d-flex justify-end pr-2">
              <v-btn
                width="75%"
                depressed
                outlined
                color="#0099dc"
                :small="screenWidth <= 960"
                @click="goToSubPurchase(false)"
                >Update</v-btn
              >
            </v-col>
            <v-col cols="6" class="d-flex justify-start pl-2">
              <v-btn
                width="75%"
                depressed
                color="#0099dc"
                class="white--text"
                :small="screenWidth <= 960"
                @click="goToSubPurchase(true)"
                >Pay</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="screenWidth <= 960" class="pt-5" no-gutters>
        <v-col
          :cols="cols.smallScreenOptions.outer"
          :class="['d-flex align-center py-2', classes.header.text]"
        >
          <v-row no-gutters>
            <v-col :cols="cols.smallScreenOptions.inner.left.head"
              ><div>Unit Price:</div></v-col
            >
            <v-col
              :cols="cols.smallScreenOptions.inner.left.val"
              :class="[
                'd-flex',
                screenWidth < 600 ? 'justify-end' : 'justify-start',
              ]"
              ><div>
                {{ currencySymbol[packageInfo.currency]
                }}{{ packageInfo.price }}/per student
              </div></v-col
            >
          </v-row>
        </v-col>
        <v-col
          :cols="cols.smallScreenOptions.outer"
          :class="classes.header.text"
          class="py-2"
        >
          <v-row no-gutters>
            <!-- <v-col
            :cols="cols.smallScreenOptions.inner.right.head"
            :class="classes.ssoirh"
            ><div class="d-flex align-center">Auto-renewal:</div></v-col
          >
          <v-col :cols="cols.smallScreenOptions.inner.right.val">
            <v-menu v-model="showAutoRenewMenu">
              <template #activator="{ on, attrs }">
                <div
                  style="height: 100%; width: 100%"
                  :class="[
                    'd-flex align-center',
                    screenWidth < 600 ? 'justify-end' : 'justify-center',
                    {
                      'inert grey--text': packageInfo.autoRenewal === undefined,
                    },
                  ]"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ outgoing.autoRenewal ? "on" : "off" }}
                  <v-icon :class="classes.renewalIcon">mdi-chevron-down</v-icon>
                </div>
              </template>
              <v-list>
                <v-list-item
                  class="clickable"
                  @click="outgoing.autoRenewal = true"
                  >on</v-list-item
                >
                <v-list-item
                  class="clickable"
                  @click="outgoing.autoRenewal = false"
                  >off</v-list-item
                >
              </v-list>
            </v-menu>
          </v-col> -->
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="screenWidth < 600" no-gutters>
        <v-col cols="6" class="pr-2">
          <v-btn
            block
            depressed
            outlined
            color="#0099dc"
            :small="smallActionButton"
            @click="goToSubPurchase(false)"
            >Update</v-btn
          >
        </v-col>
        <v-col cols="6" class="pl-2">
          <v-btn
            block
            depressed
            color="#0099dc"
            class="white--text"
            :small="smallActionButton"
            @click="goToSubPurchase(true)"
            >Pay</v-btn
          >
        </v-col>
      </v-row>
      <v-row :class="classes.filler" no-gutters> </v-row>
    </v-sheet>
    <v-sheet
      v-else
      style="width: 100%"
      :class="[classes.sheet, 'd-flex align-center justify-center']"
    >
      <no-content-component
        v-if="networkError"
        message="Data Unavailable"
      ></no-content-component>
      <v-progress-circular v-else color="primary" indeterminate></v-progress-circular>
    </v-sheet>
  </div>
</template>

<script>
import SubmissionAlertMessage from "/global/components/SubmissionAlertMessage";
import NoContentComponent from "/global/components/NoContentComponent";

export default {
  name: "StudentSubscription",
  components: { SubmissionAlertMessage, NoContentComponent },
  data() {
    return {
      renderNow: false,
      networkError: false,
      outgoing: {},
      showAutoRenewMenu: false,
      changed: false,
      saving: false,
      showBanner: false,
    };
  },
  computed: {
    screenWidth() {
      return this.$vuetify.breakpoint.width;
    },
    classes() {
      let s = this.screenWidth;
      const dateDiff = Math.ceil(
        (new Date(this.packageInfo.deadline) - new Date()) / (1000 * 3600 * 24)
      );
      return {
        sheet: `bg-white px-${this.breakPointValues(
          "3",
          "3",
          "3",
          "8"
        )} py-${this.breakPointValues("3", "3", "3", "5")}`,
        history: {
          padding: `py-${this.breakPointValues(
            2,
            4,
            4,
            4
          )} px-${this.breakPointValues(3, 0, 0, 0)}`,
          border:
            this.screenWidth < 600
              ? "border border-solid border-default rounded"
              : "border-0 border-b border-solid border-default",
          margin: `mt-${this.breakPointValues(3, 0, 0, 0)}`,
          firstItem: `pl-${this.breakPointValues(0, 9, 10, 11)}`,
        },
        header: {
          padding: `px-${
            s < 600 ? "0" : s < 961 ? "0" : s < 1903 ? "0" : "0"
          } py-${s < 600 ? "1" : s < 961 ? "1" : s < 1903 ? "1" : "2"}`,
          text: `ft-medium xl:text-sm lg:text-sm md:text-xs sm:text-xs`,
        },
        nextPayment: dateDiff < -1 ? "decline--text" : "",
        filler: `py-${s < 600 ? "2" : s < 961 ? "3" : s < 1903 ? "3" : "4"}`,
        renewalIcon: `chevron${this.showAutoRenewMenu ? "-rotate" : ""}`,
        inputSpinUp:
          this.outgoing.students > this.packageInfo.students
            ? "input-spin-button-style-active"
            : "",
        inputSpinDown:
          this.outgoing.students < this.packageInfo.students
            ? "input-spin-button-style-active"
            : "",
        ssoirh: this.screenWidth < 600 ? "" : "d-flex justify-end",
      };
    },
    sizes() {
      return {
        historyContainer: {
          height: this.breakPointValues(204, 147, 159, 159),
        },
      };
    },
    cols() {
      return {
        history: {
          title: this.screenWidth < 600 ? "12" : "5",
          start: this.screenWidth < 600 ? "12" : "4",
          end: this.screenWidth < 600 ? "12" : "3",
        },
        table: {
          left: this.breakPointValues("12", "8", "9", "9"),
          right: this.breakPointValues("0", "4", "3", "3"),
        },
        smallScreenOptions: {
          outer: this.screenWidth < 600 ? "12" : "6",
          inner: {
            left: {
              head: this.screenWidth < 600 ? "6" : "3",
              val: this.screenWidth < 600 ? "6" : "5",
            },
            right: {
              head: this.screenWidth < 600 ? "6" : "8",
              val: this.screenWidth < 600 ? "6" : "4",
            },
          },
        },
      };
    },
    packageInfo() {
      const info = this.$store.getters["transaction/subscription"];
      return {
        price: info.unit_price,
        plan: info.subscription_plan,
        upgradeList: info.upgrade_cost,
        currency: info.currency,
        students: info.num_students,
        autoRenewal: info.auto_renew,
        deadline: info.deadline,
        subscriptionEnd: info.subscription_end,
      };
    },
    colors() {
      return {
        spinButtonUp: this.classes.inputSpinUp ? "white" : "black",
        spinButtonDown: this.classes.inputSpinDown ? "white" : "black",
      };
    },
    currencySymbol() {
      return {
        bdt: "৳",
        usd: "$",
      };
    },
    smallActionButton() {
      return this.screenWidth <= 960 && this.screenWidth >= 600;
    },
    validTill() {
      if (this.packageInfo.subscriptionEnd === undefined) return "--/--/--";
      let dateObject = new Date(this.packageInfo.subscriptionEnd);
      return `${dateObject.toLocaleString("en-gb", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })}`;
    },
    headerNexPayment() {
      if (this.classes.nextPayment === "") return "Valid Till";
      return "Expired At";
    },
    nextPayment() {
      // if (this.packageInfo.deadline === undefined) return "--/--/--";
      let dateObject = new Date(this.packageInfo.deadline);
      dateObject.setDate(dateObject.getDate() + 1);
      return `${dateObject.toLocaleString("en-gb", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })}`;
    },
    plansToMonths() {
      return {
        monthly: 1,
        quarterly: 3,
        annually: 12,
      };
    },
    totalPrice() {
      return String(
        this.$store.getters["transaction/subscription"].total_amount ?? `N/A`
      );
    },
    canUpdateCondition() {
      if (this.packageInfo.upgradeList === undefined) return false;
      return this.changed;
    },
    newPurchaseText() {
      if (
        this.packageInfo.deadline === undefined ||
        this.classes.nextPayment.length > 0
      ) {
        let date_start = new Date();
        let date_end = new Date(date_start.getTime());

        if (this.packageInfo.plan === undefined)
          date_end.setMonth(date_end.getMonth() + 1);
        else
          date_end.setMonth(
            date_end.getMonth() + this.plansToMonths[this.packageInfo.plan]
          );

        return `${date_start.toLocaleString("en-gb", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })} to ${date_end.toLocaleString("en-gb", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })}`;
      } else {
        let date_end = new Date(this.packageInfo.deadline);
        date_end.setDate(date_end.getDate() + 1);
        date_end.setMonth(
          date_end.getMonth() + this.plansToMonths[this.packageInfo.plan]
        );

        return `(${this.nextPayment} to ${date_end.toLocaleString("en-gb", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })})`;
      }
    },
    showHistoryCondition() {
      if (!(this.packageInfo.upgradeList === undefined)) {
        return this.renderNow && this.packageInfo.upgradeList.length > 0;
      } else {
        return false;
      }
    },
    historyContent() {
      if (!(this.packageInfo.upgradeList === undefined)) {
        return this.packageInfo.upgradeList; //.slice(1);
      } else {
        return [];
      }
    },
    bannerMessage() {
      if (this.packageInfo.upgradeList === undefined)
        return "Currently you do not have any active subscriptions to update";
      return "You can only update if number of total students changes";
    },
  },
  watch: {
    "outgoing.students": {
      handler(value, old) {
        if (value != this.packageInfo.students) {
          this.changed = true;
        } else {
          this.changed = false;
        }

        if (value == "" || value === undefined) {
          this.outgoing.students = 0;
        } else if (isNaN(parseInt(value))) {
          this.outgoing.students = old;
        } else {
          this.outgoing.students = parseInt(value);
        }
      },
    },
    canUpdateCondition: {
      handler(value) {
        if (value) this.showBanner = false;
      },
    },
  },
  async created() {
    await this.fetchStudentSubscription();
  },
  methods: {
    async fetchStudentSubscription() {
      try {
        await this.$store.dispatch("transaction/getSubscription");
        this.renderNow = true;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
        this.networkError = true;
      } finally {
        if (this.renderNow) {
          this.outgoing = {
            students: this.packageInfo.students,
            autoRenewal: this.packageInfo.autoRenewal,
          };
        }
      }
    },
    async setAutoRenew(status) {
      if (this.saving) return;
      if (this.outgoing.autoRenewal != status) {
        this.saving = true;
        try {
          await this.$store.dispatch("transaction/toggleAutoRenew", { status });
          this.outgoing.autoRenewal = status;
        } catch (e) {
          this.$root.$emit("alert", [undefined, e.message]);
        } finally {
          this.saving = false;
        }
      }
    },
    async goToSubPurchase(isPurchase) {
      if (!isPurchase && !this.canUpdateCondition) {
        if (!this.showBanner) this.showBanner = true;
        else {
          await this.$refs.banner.flash();
        }
        return;
      }
      this.$router.push({
        name: "subscription-purchase-page",
        params: {
          type: "subscription",
          price: this.packageInfo.price,
          upgradeList: this.packageInfo.upgradeList,
          currency: this.packageInfo.currency,
          countNew: this.outgoing.students,
          countOriginal: this.packageInfo.students,
          purchaseText: this.newPurchaseText,
          isPurchase,
        },
      });
    },
    breakPointValues(smValue, mdValue, lgValue, xlValue) {
      let s = this.screenWidth;
      return s < 600
        ? smValue
        : s < 961
        ? mdValue
        : s < 1903
        ? lgValue
        : xlValue;
    },
    dateFormat(date) {
      const dateObject = new Date(date);
      return dateObject.toLocaleString("en-gb", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.input-style {
  width: 45%;
  height: 100%;
  text-align: center;
  padding: 2px 0px;
}
.input-style:focus {
  outline: none;
}

.input-spin-style {
  width: 15%;
  height: 100%;
}
.input-spin-button-style {
  height: 50%;
}

.chevron {
  transition: transform 0.5s ease-out;
}
.chevron-rotate {
  transform: rotate(180deg);
}
</style>

import { render, staticRenderFns } from "./MessageSubscription.vue?vue&type=template&id=60ee7726&scoped=true"
import script from "./MessageSubscription.vue?vue&type=script&lang=js"
export * from "./MessageSubscription.vue?vue&type=script&lang=js"
import style0 from "./MessageSubscription.vue?vue&type=style&index=0&id=60ee7726&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60ee7726",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/fluent/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('60ee7726')) {
      api.createRecord('60ee7726', component.options)
    } else {
      api.reload('60ee7726', component.options)
    }
    module.hot.accept("./MessageSubscription.vue?vue&type=template&id=60ee7726&scoped=true", function () {
      api.rerender('60ee7726', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "portals/teachers-portal/src/modules/payment-transation/components/MessageSubscription.vue"
export default component.exports
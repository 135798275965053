<template>
  <v-container :class="classes.container">
    <v-tabs v-model="tab" background-color="transparent" hide-slider>
      <v-tab :class="classes.tab.general" :active-class="classes.tab.active"
        >Paid</v-tab
      >
      <v-tab :class="classes.tab.general" :active-class="classes.tab.active"
        >Due</v-tab
      >
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <paid-students-table></paid-students-table>
      </v-tab-item>
      <v-tab-item>
        <due-students-table></due-students-table>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import PaidStudentsTable from "#ecf/payment-transation/components/PaidStudentsTable.vue";
import DueStudentsTable from "#ecf/payment-transation/components/DueStudentsTable.vue";

export default {
  name: "StudentTransactionComponent",
  components: {
    PaidStudentsTable,
    DueStudentsTable
  },
  data() {
    return {
      tab: 0
    };
  },
  computed: {
    screenWidth() {
      return this.$vuetify.breakpoint.width;
    },
    styles() {
      return {
        sheet: `text-transform: none;`
      };
    },
    classes() {
      return {
        container: `pa-0`,
        tab: {
          general: `tab-general`,
          active: `tab-selected`
        }
      };
    }
  },
};
</script>

<style lang="scss" scoped>
.tab-general {
  text-transform: none;
  color: $dark;
}
.tab-general::before {
  border-radius: 10px 10px 0px 0px;
}
.tab-selected {
  background-color: white;
  border-radius: 10px 10px 0px 0px;
  opacity: 1;
}
</style>

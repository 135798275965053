<template>
  <v-container id="main" :class="['main', classes.main]">
    <h2 id="header" :class="classes.header">Payments & Transactions</h2>
    <v-tabs
      v-if="!(screenWidth < 600)"
      v-model="tab"
      class="tab-bar"
      hide-slider
      background-color="transparent"
    >
      <v-tab
        v-for="tab in tabItems"
        :key="tab"
        :class="classes.tab"
        active-class="selected-tab"
        darks
      >
        {{ tab }}
      </v-tab>
    </v-tabs>
    <v-expansion-panels v-else v-model="expTabOpen">
      <v-expansion-panel class="mb-5">
        <v-expansion-panel-header>
          <span class="sm:text-base">{{ tabItems[tab] }}</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list>
            <v-list-item
              v-for="(item, index) in tabItems"
              :key="index"
              class="sm:text-sm"
              @click="changeTab(index)"
            >
              {{ item }}
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <hr :class="classes.hr" />
    <v-tabs-items v-model="tab">
      <v-tab-item style="background-color: #f3f7ff">
        <subscription-component></subscription-component>
      </v-tab-item>
      <!-- <v-tab-item style="background-color: #f3f7ff">
        <income-component></income-component>
      </v-tab-item> -->
      <v-tab-item style="background-color: #f3f7ff">
        <transaction-component></transaction-component>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import SubscriptionComponent from "#ecf/payment-transation/components/SubcriptionComponent.vue";
import TransactionComponent from "#ecf/payment-transation/components/TransactionComponent.vue";
// import IncomeComponent from "#ecf/payment-transation/components/IncomeComponent.vue";

export default {
  name: "PaymentsTransactionsPage",
  components: { 
    SubscriptionComponent, 
    TransactionComponent, 
    // IncomeComponent 
  },
  data() {
    return {
      tab: 0,
      // tabItems: ["Subscription", "Revenue", "Transactions"],
      tabItems: ["Subscription", "Transactions"],
      expTabOpen: undefined
    };
  },
  computed: {
    screenWidth() {
      return this.$vuetify.breakpoint.width;
    },
    classes() {
      let s = this.screenWidth;
      return {
        main: `pa-${s < 600 ? 0 : s < 961 ? 2 : s < 1903 ? 4 : 8}`,
        header: `header header-${
          s < 600 ? "sm" : s < 961 ? "md" : s < 1903 ? "lg" : "xl"
        }`,
        tab: `single-tab-item xl:text-base lg:text-base md:text-sm sm:text-xs`,
        hr: `hr-style hr-${
          s < 600 ? "sm" : s < 961 ? "md" : s < 1903 ? "lg" : "xl"
        }`
      };
    }
  },
  methods: {
    changeTab(payload) {
      this.tab = payload;
      this.expTabOpen = undefined;
    }
  }
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  background-color: transparent;
  margin-left: 0px;
}

.header {
  color: $text;
}
.header-sm {
  margin-bottom: 30px;
}
.header-md {
  margin-bottom: 30px;
}
.header-lg {
  margin-bottom: 30px;
}
.header-xl {
  margin-bottom: 30px;
}

.hr-style {
  color: $primary;
}
.hr-sm {
  width: 100%;
}
.hr-md {
  width: min(800px, 100%);
}
.hr-lg {
  width: min(1024px, 100%);
}
.hr-xl {
  width: 1024px;
}

.tab-bar {
  border-bottom-color: #6fc4e9;
}
.single-tab-item {
  text-transform: none;
  color: $dark;
}
.single-tab-item::before {
  border-radius: 10px 10px 0px 0px;
  @media screen and (max-width: 599px) {
    border-radius: 10px 10px 10px 10px;
  }
}
.selected-tab {
  background-color: #d6f3ff;
  border-radius: 10px 10px 0px 0px;
  opacity: 1;
  @media screen and (max-width: 599px) {
    border-radius: 10px 10px 10px 10px;
  }
}
</style>

<template>
  <div>
    <v-sheet v-if="renderNow" style="width: 100%" :class="classes.sheet">
      <v-row no-gutters>
        <span
          class="xl:text-sm lg:text-sm md:text-xs sm:text-xxs grey--text inert"
          >In this package you will be charged
          {{ packageInfo.currency.toUpperCase() }} {{ packageInfo.price }}/per
          SMS</span
        >
      </v-row>
      <v-row
        no-gutters
        :class="[
          'mt-6 border border-solid border-default rounded',
          classes.header.padding,
        ]"
      >
        <v-col
          :class="['d-flex align-center justify-center', classes.header.text]"
          :cols="cols.content.left"
          >Balance</v-col
        >
        <v-col
          :class="['d-flex align-center justify-center', classes.header.text]"
          :cols="cols.content.mid"
          >Last Purchase</v-col
        >
        <v-col
          v-if="!(screenWidth < 600)"
          :class="['d-flex align-center justify-center', classes.header.text]"
        >
          Actions
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-4">
        <v-col
          :class="['d-flex align-center justify-center', classes.header.text]"
          :cols="cols.content.left"
        >
          <input
            v-model="outgoing.messages"
            type="text"
            class="inert input-style border border-solid border-default rounded xl:text-base"
          />
        </v-col>
        <v-col
          :class="['d-flex align-center justify-center', classes.header.text]"
          :cols="cols.content.mid"
          >{{ lastPayment }}</v-col
        >
        <v-col
          class="d-flex align-center justify-center"
          :class="classes.button"
          :cols="cols.content.right"
        >
          <v-btn
            :block="screenWidth < 600"
            depressed
            color="#0099dc"
            class="white--text"
            :small="smallActionButton"
            @click="goToSubPurchase(true)"
            >Buy New SMS</v-btn
          >
        </v-col>
      </v-row>
      <v-row :class="classes.filler" no-gutters> </v-row>
    </v-sheet>
    <v-sheet
      v-else
      style="width: 100%"
      :class="[classes.sheet, 'd-flex align-center justify-center']"
    >
      <no-content-component
        v-if="networkError"
        message="Data Unavailable"
      ></no-content-component>
      <v-progress-circular
        v-else
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-sheet>
    <div v-show="showMessageHistoryCondition" class="mt-5">
      <v-sheet class="mt-10" :class="classes.sheet">
        <v-row no-gutters>
          <div class="ft-bold xl:text-lg lg:text-lg md:text-base sm:text-sm">
            History
          </div>
        </v-row>
        <v-row
          no-gutters
          :class="[
            'mt-6 border border-solid border-default rounded',
            classes.header.padding,
          ]"
        >
          <v-col
            :class="[
              'd-flex align-center justify-start',
              classes.header.text,
              classes.header.colPadding,
            ]"
            cols="4"
            >Title</v-col
          >
          <v-col
            :class="['d-flex align-center justify-center', classes.header.text]"
            cols="3"
            >Date</v-col
          >
          <v-col
            :class="['d-flex align-center justify-center', classes.header.text]"
            cols="3"
          >
            Price
          </v-col>
          <v-col
            :class="['d-flex align-center justify-center', classes.header.text]"
            cols="2"
            >Status</v-col
          >
        </v-row>
        <div v-for="(subH, i) in subscriptionHistory.transactions" :key="i">
          <v-row no-gutters class="pa-2">
            <v-col
              :class="[
                'd-flex align-center justify-start',
                classes.header.text,
                classes.colPadding,
              ]"
              cols="4"
            >
              Payment for {{ subH["num_of_sms"] }} sms
            </v-col>
            <v-col
              :class="[
                'd-flex align-center justify-center',
                classes.header.text,
              ]"
              cols="3"
            >
              {{ subH["date"] }}
            </v-col>
            <v-col
              :class="[
                'd-flex align-center justify-center',
                classes.header.text,
              ]"
              cols="3"
            >
              {{currencySymbol[packageInfo.currency]}}{{ subH["price"] }}
            </v-col>
            <v-col
              :class="[
                'd-flex pl-3 align-center justify-center',
                classes.header.text,
              ]"
              cols="2"
            >
              {{ subH["status"] }}
            </v-col>
          </v-row>
          <v-divider class="border-opacity-85"></v-divider>
        </div>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import NoContentComponent from "/global/components/NoContentComponent";

export default {
  name: "MessageSubscription",
  components: { NoContentComponent },
  data() {
    return {
      renderNow: false,
      historyRenderNow: false,
      networkError: false,
      outgoing: {},
      subscriptionHistory: [],
      showAutoRenewMenu: false,
      changed: false,
    };
  },
  computed: {
    screenWidth() {
      return this.$vuetify.breakpoint.width;
    },
    classes() {
      return {
        sheet: `bg-white px-${this.breakPointValues(
          "3",
          "3",
          "3",
          "8"
        )} py-${this.breakPointValues("3", "3", "3", "5")}`,
        header: {
          padding: `px-0 py-${this.breakPointValues("1", "1", "1", "2")}`,
          text: `ft-medium xl:text-sm lg:text-sm md:text-xs sm:text-xs`,
          colPadding: `pl-${this.screenWidth < 600 ? 2 : 10}`,
        },
        filler: `py-${this.breakPointValues("2", "3", "3", "4")}`,
        renewalIcon: `chevron${this.showAutoRenewMenu ? "-rotate" : ""}`,
        button: `pt-${this.screenWidth < 600 ? 4 : 0}`,
        colPadding: `pl-${this.screenWidth < 600 ? 0 : 9}`,
      };
    },
    cols() {
      return {
        content: {
          left: this.screenWidth < 600 ? "6" : "4",
          mid: this.screenWidth < 600 ? "6" : "4",
          right: this.screenWidth < 600 ? "12" : "4",
        },
      };
    },
    packageInfo() {
      const info = this.$store.getters["transaction/smsSubscription"];
      return {
        price: info.unit_price,
        currency: info.currency,
        lastPayment: info.last_payment,
        messages: info.num_of_sms === undefined ? 0 : info.num_of_sms,
      };
    },
    currencySymbol() {
      return {
        bdt: "৳",
        usd: "$",
      };
    },
    smallActionButton() {
      return this.screenWidth <= 960 && this.screenWidth >= 600;
    },
    lastPayment() {
      if (this.packageInfo.lastPayment === undefined) return `--/--/--`;
      else {
        let dateObject = new Date(this.packageInfo.lastPayment);
        dateObject.setDate(dateObject.getDate() + 1);
        return `${dateObject.toLocaleString("en-gb", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })}`;
      }
    },
    showMessageHistoryCondition() {
      return this.subscriptionHistory.transactions?.length > 0;
    },
  },
  async created() {
    await this.fetchMessageSubscription();
    await this.fetchMessageSubscriptionHistory();
  },
  methods: {
    breakPointValues(smValue, mdValue, lgValue, xlValue) {
      let s = this.screenWidth;
      return s < 600
        ? smValue
        : s < 961
        ? mdValue
        : s < 1903
        ? lgValue
        : xlValue;
    },
    async fetchMessageSubscription() {
      try {
        await this.$store.dispatch("transaction/getSMS");
        this.renderNow = true;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
        this.networkError = true;
      } finally {
        if (this.renderNow) {
          this.outgoing = {
            messages: this.packageInfo.messages,
          };
        }
      }
    },
    async fetchMessageSubscriptionHistory() {
      try {
        this.subscriptionHistory = await this.$store.dispatch(
          "transaction/getSMSHistory"
        );
        this.historyRenderNow = true;
      } catch (e) {
        this.$root.$emit("alert", [undefined, JSON.parse(e.message).message]);
        this.networkError = true;
      }
    },
    async goToSubPurchase(isPurchase) {
      await this.$router.push({
        name: "subscription-purchase-page",
        params: {
          type: "sms",
          price: this.packageInfo.price,
          currency: this.packageInfo.currency,
          countNew: 0,
          isPurchase,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.input-style {
  width: 45%;
  height: 100%;
  text-align: center;
  padding: 2px 0px;
}
.input-style:focus {
  outline: none;
}

.chevron {
  transition: transform 0.5s ease-out;
}
.chevron-rotate {
  transform: rotate(180deg);
}
</style>

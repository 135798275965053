<template>
  <v-sheet
    v-if="loading.status"
    height="200"
    class="d-flex align-center justify-center"
  >
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </v-sheet>
  <v-container v-else :class="classes.container">
    <v-row
      v-if="pages > 0 && false"
      id="search"
      no-gutters
      class="pb-5 pt-2 justify-space-between"
    >
      <v-col :cols="cols.searchBar" class="pb-5">
        <input
          v-model="nameSearch"
          class="search-style rounded rounded-r-0"
          type="text"
          placeholder="Search by student, course, package name"
        />
        <v-btn
          class="elevation-0 rounded-l-0"
          color="primary"
          min-width="35"
          width="35"
          height="35"
        >
          <v-icon color="white">search</v-icon>
        </v-btn>
      </v-col>
      <v-col
        v-if="!(screenWidth > 960) && !(screenWidth < 600)"
        cols="6"
        class="d-flex justify-end"
      >
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <input
              :style="`width: ${sizes.filterBar.width}`"
              class="input-style"
              type="text"
              placeholder="Filter by date"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
        </v-menu>
      </v-col>
      <v-col
        v-if="!(screenWidth < 600)"
        :cols="cols.filters.outer"
        class="d-flex align-center justify-space-between pb-2"
      >
        <v-menu v-if="screenWidth > 960" offset-y>
          <template #activator="{ on, attrs }">
            <input
              :style="`width: ${sizes.filterBar.width}`"
              class="input-style"
              type="text"
              placeholder="Filter by date"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
        </v-menu>
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <input
              :style="`width: ${sizes.filterBar.width}`"
              class="input-style"
              type="text"
              placeholder="Filter by Course/Package"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
        </v-menu>
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <input
              :style="`width: ${sizes.filterBar.width}`"
              class="input-style"
              type="text"
              placeholder="Filter by batch"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
        </v-menu>
      </v-col>
      <v-col
        :cols="cols.action"
        class="d-flex align-center justify-space-between pb-2"
      >
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <input
              style="width: calc(100% - 75px)"
              class="input-style"
              type="text"
              readonly
              v-bind="attrs"
              :value="selectedAction"
              v-on="on"
            />
          </template>
          <v-list class="pa-0">
            <v-list-item
              v-for="(action, index) in actionOptions"
              :key="index"
              style="min-height: 36px"
              @click="dueAction(action)"
            >
              <span class="xl:text-sm lg:text-sm md:text-xs sm:text-xs">{{
                action
              }}</span>
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- <input class="input-style" type="text"> -->
        <v-btn
          width="70"
          depressed
          color="blue"
          class="white--text"
          @click="enforceAction"
          >Save</v-btn
        >
      </v-col>
    </v-row>
    <v-row
      v-if="pages > 0"
      id="table-heading"
      no-gutters
      style="color: #808080"
      class="rounded border border-solid border-default ft-bold xl:text-sm lg:text-sm md:text-xs sm:text-xs"
      :class="classes.rowHeader"
    >
      <v-col :cols="cols.table.left.outer">
        <v-row no-gutters>
          <v-col
            :cols="cols.table.left.check"
            class="d-flex align-center justify-center"
            ><v-checkbox
              v-model="allSelected"
              dense
              hide-details
              class="pa-0 ma-0"
              @click="selectAllFunction"
            ></v-checkbox
          ></v-col>
          <v-col :cols="cols.table.left.name" class="d-flex align-center"
            >Student Name</v-col
          >
          <v-col v-if="screenWidth > 960" cols="3" class="d-flex align-center"
            >Course</v-col
          >
          <v-col v-if="screenWidth > 960" cols="3" class="d-flex align-center"
            >Batch</v-col
          >
        </v-row>
      </v-col>
      <v-col :cols="cols.table.right.outer" class="d-flex align-center">
        <v-row no-gutters>
          <v-col
            v-if="!(screenWidth < 600)"
            cols="5"
            class="d-flex align-center justify-center"
            >Due Date</v-col
          >
          <v-col
            v-if="!(screenWidth < 600)"
            cols="3"
            class="d-flex align-center justify-center"
            >Days</v-col
          >
          <v-col
            :cols="cols.table.right.amount"
            class="d-flex align-center justify-center"
            >Amount</v-col
          >
        </v-row>
      </v-col>
    </v-row>
    <v-row
      v-for="(due, index) in dues"
      :key="index"
      no-gutters
      class="ft-medium xl:text-base lg:text-sm md:text-sm sm:text-xxs"
      :class="classes.rowEntry"
    >
      <v-col :cols="cols.table.left.outer">
        <v-row no-gutters>
          <v-col
            :cols="cols.table.left.check"
            class="d-flex align-center justify-center"
          >
            <v-checkbox
              v-model="selected"
              dense
              hide-details
              class="pa-0 ma-0"
              :value="index"
            ></v-checkbox>
          </v-col>
          <v-col :cols="cols.table.left.name" class="d-flex align-center">
            <div class="text-truncate">{{ name(due) }}</div>
          </v-col>
          <v-col
            v-if="screenWidth > 960"
            cols="3"
            class="d-flex align-center"
            >{{ purchaseName(due) }}</v-col
          >
          <v-col
            v-if="screenWidth > 960"
            cols="3"
            class="d-flex align-center"
            >{{ purchaseSubName(due) }}</v-col
          >
        </v-row>
      </v-col>
      <v-col :cols="cols.table.right.outer" class="d-flex align-center">
        <v-row no-gutters>
          <v-col
            v-if="!(screenWidth < 600)"
            cols="5"
            class="d-flex align-center justify-center"
            >{{ dueDate(due) }}</v-col
          >
          <v-col
            v-if="!(screenWidth < 600)"
            cols="3"
            class="d-flex align-center justify-center"
            >{{ daysBehind(due) }}</v-col
          >
          <v-col
            :cols="cols.table.right.amount"
            class="d-flex align-center justify-center"
            >{{ dueAmount(due) }} {{ due.currency.toUpperCase() }}</v-col
          >
        </v-row>
      </v-col>
      <v-col
        v-if="!(screenWidth > 960)"
        :cols="cols.table.action"
        class="d-flex justify-center"
      >
        <v-btn height="20" icon @click="toggleHidden(index)">
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" :class="classes.rowEntryHidden">
        <div
          style="background-color: #f8f8fa"
          class="hidden-data border-solid border-default border-0 border-t"
          :class="[showHiddenCondition(index) ? 'hidden-data-expand' : '']"
        >
          <div
            class="pa-4 border-solid border-default border-0 border-b"
            :class="{ 'd-flex': !(screenWidth < 600) }"
          >
            <div
              v-if="!(screenWidth < 600)"
              style="width: calc(100% / 12)"
            ></div>
            <div v-if="screenWidth < 600" class="pb-1">
              Date: <span class="pl-2">{{ dueDate(due) }}</span>
            </div>
            <div v-if="screenWidth < 600" class="pb-1">
              Days Behind: <span class="pl-2">{{ daysBehind(due) }}</span>
            </div>
            <div class="pb-1">
              Course: <span class="pl-2">{{ purchaseName(due) }}</span>
            </div>
            <div class="pb-1" :class="{ 'pl-5': !(screenWidth < 600) }">
              Batch: <span class="pl-2">{{ purchaseSubName(due) }}</span>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <no-content-component
      v-if="pages == 0"
      :height="sizes.noItemBox.height"
      message="You have no due payments"
    ></no-content-component>
    <div v-if="pages > 0">
      <v-pagination
        v-if="!(screenWidth < 600)"
        v-model="page"
        :length="pages"
        :total-visible="sizes.paginator.totalVisible"
        :class="classes.pagination"
      ></v-pagination>
      <v-row v-if="screenWidth < 600" no-gutters class="pt-5">
        <v-col cols="7" class="d-flex align-center justify-end">
          <span class="ft-bold sm:text-sm">Page: </span>
        </v-col>
        <v-col cols="5" class="d-flex align-center justify-end">
          <div style="width: 75%">
            <v-select
              v-model="page"
              outlined
              dense
              hide-details
              no-data-text="No Due Students"
              :items="pageItems"
            ></v-select>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import NoContentComponent from "/global/components/NoContentComponent";
import { PaymentFetch } from "#ef/payment-transaction/mixins.js";

export default {
  name: "DueStudentsTable",
  components: { NoContentComponent },
  mixins: [PaymentFetch],
  data() {
    return {
      loading: {},
      actionOptions: [
        "Change Payment Status",
        "Send E-mail",
        "Send SMS",
        "Make Inactive",
        "Make Active",
      ],
      nameSearch: "",
      selectedAction: undefined,
      page: 1,
      selected: [],
      allSelected: false,
      all: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      saving: false,
      showHidden: {},
    };
  },
  computed: {
    screenWidth() {
      return this.$vuetify.breakpoint.width;
    },
    classes() {
      return {
        container: `pa-${this.breakPointValues(3, 3, 5, 6)}`,
        rowHeader: `px-0 py-2`,
        rowEntry: `px-0 pt-5`,
        rowEntryHidden: `pt-5`,
        pagination: `pt-${this.breakPointValues(4, 4, 4, 5)}`,
      };
    },
    sizes() {
      return {
        methodIcon: {
          width: 45,
        },
        filterBar: {
          width: this.breakPointValues(
            "",
            "calc(50% - 7px)",
            "calc(33% - 10px)",
            "calc(33% - 10px)"
          ),
        },
        paginator: {
          totalVisible: this.breakPointValues(2, 5, 7, 7),
        },
        noItemBox: {
          height: 200,
        },
      };
    },
    cols() {
      return {
        searchBar: this.breakPointValues("12", "6", "12", "12"),
        filters: {
          outer: this.breakPointValues("6", "6", "7", "7"),
          inner: this.breakPointValues("12", "12", "9", "9"),
          sub: this.breakPointValues("6", "6", "5", "5"),
        },
        action: this.breakPointValues("12", "5", "4", "4"),
        table: {
          left: {
            outer: this.breakPointValues("6", "5", "8", "8"),
            check: this.breakPointValues("4", "3", "1", "1"),
            name: this.breakPointValues("8", "9", "5", "5"),
          },
          right: {
            outer: this.breakPointValues("4", "6", "4", "4"),
            due: this.breakPointValues("2", "2", "5", "5"),
            days: this.breakPointValues("6", "3", "3", "3"),
            amount: this.breakPointValues("12", "4", "4", "4"),
          },
          action: this.breakPointValues("2", "1", "", ""),
        },
      };
    },
    pages() {
      return this.$store.state.transaction.dueStudentPages || 0;
    },
    dues() {
      let listItems = this.listDues;
      if (listItems === undefined) return [];
      else return listItems[this.page];
    },
    listDues() {
      return this.$store.getters["transaction/dueStuents"];
    },
    pageItems() {
      let n = this.pages;
      return Array.from({ length: n }, (_, index) => index + 1);
    },
  },
  watch: {
    page: {
      async handler(value) {
        if (!(value in this.listDues)) {
          await this.bufferFetch(this.loading, async () => {
            await this.fetchDueStudents(value);
          });
        }
        this.allSelected = false;
        this.selected = [];
      },
    },
  },
  async created() {
    await this.bufferFetch(this.loading, async () => {
      await this.fetchDueStudentPages();
      await this.fetchDueStudents(this.page);
    });
  },
  methods: {
    breakPointValues(smValue, mdValue, lgValue, xlValue) {
      let s = this.screenWidth;
      return s < 600
        ? smValue
        : s < 961
        ? mdValue
        : s < 1903
        ? lgValue
        : xlValue;
    },
    selectAllFunction() {
      if (this.allSelected) this.selected = this.all;
      else this.selected = [];
    },
    dueAction(item) {
      this.selectedAction = item;
    },
    name(item) {
      return item.initiator_name;
    },
    purchaseName(item) {
      if (!("course_title" in item)) {
        return item["package_title"];
      }
      return item["course_title"];
    },
    purchaseSubName(item) {
      if (!("batch_title" in item)) {
        return "";
      }
      return item["batch_title"];
    },
    dueDate(item) {
      let parts;
      if (!("next_payment_date" in item)) {
        parts = item.updated_at.split("-");
      } else {
        parts = item.next_payment_date.split("-");
      }
      let date = new Date(
        Number(parts[0]),
        Number(parts[1] - 1),
        Number(parts[2])
      ).toLocaleString("en-gb", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
      return date;
    },
    daysBehind(item) {
      let parts;
      if (!("next_payment_date" in item)) {
        parts = item.updated_at.split("-");
      } else {
        parts = item.next_payment_date.split("-");
      }
      let diff =
        new Date() -
        new Date(Number(parts[0]), Number(parts[1] - 1), Number(parts[2]));
      return Math.ceil(diff / (24 * 3600 * 1000));
    },
    dueAmount(item){
      return item.dues
    },
    toggleHidden(index) {
      if (!(index in this.showHidden)) {
        this.$set(this.showHidden, index, true);
      } else {
        this.showHidden[index] = !this.showHidden[index];
      }
    },
    showHiddenCondition(index) {
      if (this.screenWidth > 960) {
        return false;
      }
      if (this.showHidden[index] === undefined) return false;
      return this.showHidden[index];
    },
    async fetchDueStudentPages() {
      await this.$store.dispatch("transaction/pagesDueStudents");
    },
    async fetchDueStudents(page) {
      await this.$store.dispatch("transaction/listDueStudents", {
        page,
      });
    },
    async enforceAction() {
      if (this.selectedAction === undefined || this.saving) return;
      this.saving = true;
      try {
        let list_outgoing = [];
        for (let item of this.selected) {
          if (item >= this.dues.length) break;
          let data_outgoing = {
            student_id: this.dues[item].recipient_id,
            action: this.selectedAction,
          };
          if (!(this.dues.course_id === undefined)) {
            data_outgoing["course_id"] = this.dues[item].course_id;
            data_outgoing["batch_id"] = this.dues[item].batch_id;
          } else {
            data_outgoing["package_id"] = this.dues[item].package_id;
          }
          list_outgoing.push(data_outgoing);
        }
        // this.$store.dispatch("transaction/enforceAction", {data: data_outgoing})
        this.$root.$emit("alert", [
          "Notice",
          "this feature is currently not complete",
        ]);
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search-style {
  height: 35px;
  width: calc(100% - 35px);
  padding: 0px 10px;
  border: 1px solid $border;
}
.search-style:focus {
  outline: none;
}

.input-style {
  height: 35px;
  padding: 0px 10px;
  border: 1px solid $border;
  border-radius: 3px;
  // outline: 2px solid white;
}
.input-style:focus {
  // outline: none;
  // border: 2px solid $primary;
  outline: 2px solid $primary;
  height: 33px;
}

.hidden-data {
  overflow: hidden;
  max-height: 0px;
  transition: max-height 0.5s ease-in-out;
}
.hidden-data-expand {
  max-height: 400px;
}
</style>

<template>
  <v-sheet v-if="renderNow" style="width: 100%" :class="classes.sheet">
    <div
      v-for="(tr, idx) in transactions"
      :key="idx"
      :class="classes.card"
      :style="styles.card"
      class="rounded border border-solid border-default"
      style="background-color: #f5f5f5"
    >
      <v-row no-gutters style="height: 100%">
        <v-col :cols="cols.info.left" class="d-flex align-center">
          <!-- <v-img :src="icons.card" :height="sizes.cardIcon.height"></v-img> -->
          <img
            v-if="!(screenWidth < 600)"
            :src="icons.card"
            alt="transaction-image"
            :style="`width: ${sizes.cardIcon.width}px;`"
          />
          <div
            class="d-flex flex-column"
            :class="classes.cardHeader"
            style="width: 75%"
          >
            <div
              class="text-truncate ft-bold xl:text-base lg:text-base md:text-sm sm:text-sm"
              :title="tr.title"
            >
              {{ tr.title }}
            </div>
            <div class="ft-light xl:text-xs lg:text-xs md:text-xxs sm:text-xxs">
              Date: {{ dateFormat(tr.transaction_time) }}
            </div>
          </div>
        </v-col>
        <v-col :cols="cols.info.right" class="d-flex align-center">
          <v-row no-gutters>
            <v-col v-if="vendorCondition" cols="7" class="d-flex flex-column">
              <div
                class="ft-light xl:text-xs lg:text-xs md:text-xxs sm:text-xxs"
              >
                Method: {{ tr.payment_method || "Bypassed" }}
              </div>
              <div
                :style="`width: ${sizes.sessionId.width}`"
                class="ft-light xl:text-xs lg:text-xs md:text-xxs sm:text-xxs mt-2 text-truncate"
              >
                Transaction ID: {{ tr.payment_method_session_id || "N\A" }}
              </div>
            </v-col>
            <v-col
              :cols="screenWidth > 960 ? '5' : '12'"
              :class="`d-flex align-center ${
                screenWidth > 960 ? 'justify-space-between' : 'justify-end'
              }`"
            >
              <v-row no-gutters>
                <v-col cols="9" class="d-flex justify-end">
                  <span
                    v-if="!(screenWidth < 600) && wasComplete(tr)"
                    class="xl:text-2xl lg:text-2xl md:text-base sm:text-base primary--text d-flex align-center"
                    >{{ numberFormat(tr.amount)
                    }}{{ currencySymbol[tr.currency] }}</span
                  >
                  <span
                    v-if="!wasComplete(tr)"
                    class="xl:text-xl lg:text-xl md:text-base sm:text-sm decline--text d-flex align-center"
                    :class="{ 'mr-2': screenWidth < 600 }"
                  >
                    {{ tr.status === "aborted" ? "Aborted" : "Failed" }}
                  </span>
                </v-col>
                <v-col cols="3" class="d-flex justify-end">
                  <v-menu offset-y>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        icon
                        :style="styles.button"
                        v-on="on"
                      >
                        <img
                          alt="menu-image"
                          :src="icons.menu"
                          :style="`height: ${sizes.menuIcon.height}px;`"
                        />
                      </v-btn>
                    </template>
                    <v-list
                      class="elevation-0 rounded border-solid border-default border-1-2 py-0"
                    >
                      <v-list-item
                        class="px-3 xl:text-base lg:text-base md:text-sm sm:text-xs"
                        :class="{ inert: !tr.payment_method_session_id }"
                        @click="copySessionId(tr.payment_method_session_id)"
                      >
                        <span
                          :class="{
                            'grey--text': !tr.payment_method_session_id,
                          }"
                          >Copy ID</span
                        >
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item
                        class="px-3 xl:text-base lg:text-base md:text-sm sm:text-xs"
                        :class="{ inert: !wasComplete(tr) }"
                        @click="showReceipt(tr.transaction_id)"
                      >
                        <span :class="{ 'grey--text': !wasComplete(tr) }"
                          >View Receipt</span
                        >
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item
                        class="px-3 xl:text-base lg:text-base md:text-sm sm:text-xs"
                        :class="{ inert: !wasComplete(tr) }"
                        @click="downloadReceipt(tr.transaction_id)"
                      >
                        <span :class="{ 'grey--text': !wasComplete(tr) }"
                          >Download</span
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="!vendorCondition" cols="12" :class="classes.vendor">
          <div
            class="ft-light xl:text-xs lg:text-xs md:text-xxs sm:text-xxs"
            :class="screenWidth < 600 ? '' : 'pl-9'"
            :style="`width: ${sizes.fakeSize}`"
          >
            Method: {{ tr.payment_method || "N/A" }}
          </div>
          <div
            :style="`width: ${sizes.sessionId.width}`"
            :class="screenWidth < 600 ? '' : 'pl-9'"
            class="ft-light xl:text-xs lg:text-xs md:text-xxs sm:text-xxs text-truncate"
          >
            Transaction ID: {{ tr.payment_method_session_id || "N/A" }}
          </div>
        </v-col>
      </v-row>
    </div>
    <no-content-component
      v-if="pages == 0"
      message="You have no transaction history"
      :height="sizes.noItemBox.height"
    ></no-content-component>
    <div v-if="pages > 0" class="pt-5">
      <v-pagination
        v-if="screenWidth >= 600"
        v-model="page"
        :length="pages"
        :total-visible="sizes.paginator.totalVisible"
      ></v-pagination>
      <v-row v-if="screenWidth < 600" no-gutters>
        <v-col cols="7" class="d-flex align-center justify-end">
          <span class="ft-bold sm:text-sm">Page: </span>
        </v-col>
        <v-col cols="5" class="d-flex align-center justify-end">
          <div style="width: 75%">
            <v-select
              v-model="page"
              outlined
              dense
              hide-details
              no-data-text="No Transactions"
              :items="pageItems"
            ></v-select>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="showReceiptMenu" width="75%" class="rounded">
      <receipt-dialog :contents="receiptContent"></receipt-dialog>
    </v-dialog>
  </v-sheet>
  <v-sheet
    v-else
    height="200"
    :class="[classes.sheet, 'd-flex align-center justify-center']"
  >
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </v-sheet>
</template>

<script>
import ReceiptDialog from "#ecf/payment-transation/components/ReceiptDialog.vue";
import NoContentComponent from "/global/components/NoContentComponent";

export default {
  name: "MyTransactionComponent",
  data() {
    return {
      page: 1,
      renderNow: false,
      showReceiptMenu: false,
      receiptContent: undefined,
    };
  },
  components: { ReceiptDialog, NoContentComponent },
  computed: {
    screenWidth() {
      return this.$vuetify.breakpoint.width;
    },
    vendorCondition() {
      return this.screenWidth > 960;
    },
    styles() {
      return {
        card: `height: ${this.breakPointValues(110, 90, 80, 100)}px`,
        button: `${this.breakPointValues("width: 18px", "", "", "")}`,
      };
    },
    classes() {
      return {
        sheet: `bg-white pa-${this.breakPointValues(4, 4, 4, 6)}`,
        card: `pa-${this.breakPointValues(2, 4, 4, 4)}`,
        cardHeader: `pl-${this.breakPointValues(0, 4, 4, 6)}`,
        vendor: this.breakPointValues([
          "pt-2 d-flex flex-column",
          "py-2 d-flex justify-start align-center",
          "",
          "",
        ]),
      };
    },
    sizes() {
      return {
        cardIcon: {
          height: this.breakPointValues(15, 20, 20, 45),
          width: this.breakPointValues(15, 20, 20, 45),
        },
        menuIcon: {
          height: this.breakPointValues(20, 20, 20, 25),
          width: this.breakPointValues(20, 20, 20, 45),
        },
        sessionId: {
          width: this.breakPointValues("100%", "320px", "280px", "320px"),
        },
        fakeSize: this.screenWidth < 600 ? "100%" : "30%",
        paginator: {
          totalVisible: this.breakPointValues(2, 5, 7, 7),
        },
        noItemBox: {
          height: 200,
        },
      };
    },
    cols() {
      return {
        info: {
          left: this.breakPointValues("9", "7", "5", "5"),
          right: this.breakPointValues("3", "5", "7", "7"),
        },
      };
    },
    icons() {
      return {
        card: require("#ecf/payment-transation/assets/icons/Group 18449.svg"),
        menu: require("#ecf/payment-transation/assets/icons/Group 11148.svg"),
      };
    },
    currencySymbol() {
      return {
        bdt: "৳",
        usd: "$",
      };
    },
    pages() {
      return this.$store.state.transaction.selfTransactionPages || 0;
    },
    transactions() {
      //return transaction for the current page
      let listItems = this.ownTransactions;
      if (listItems === undefined) return [];
      else return listItems[this.page];
    },
    ownTransactions() {
      return this.$store.getters["transaction/myTransactions"];
    },
    ownCourses() {
      return this.$store.state.courses.myCourses;
    },
    pageItems() {
      let n = this.pages;
      return Array.from({ length: n }, (_, index) => index + 1);
    },
  },
  watch: {
    page: {
      handler(value) {
        if (!(value in this.ownTransactions)) {
          this.fetchMyTransactions(value);
        }
      },
    },
    receiptContent: {
      handler(value) {
        if (!value) this.receiptContent = undefined;
      },
    },
  },
  async created() {
    await this.fetchMyTransactionPages();
    await this.fetchMyTransactions(this.page);
  },
  methods: {
    breakPointValues(smValue, mdValue, lgValue, xlValue) {
      let s = this.screenWidth;
      return s < 600
        ? smValue
        : s < 961
        ? mdValue
        : s < 1903
        ? lgValue
        : xlValue;
    },
    dateFormat(date) {
      let out = new Date(date);
      return out.toLocaleString("en-gb", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    },
    numberFormat(number) {
      return Number(number).toFixed(2);
    },
    wasComplete(item) {
      if (item.status === undefined || item.status != "success") return false;
      else return true;
    },
    getFileName(url) {
      return decodeURIComponent(url.split("?")[0].split("/").pop());
    },
    copySessionId(text) {
      if (!text) return;
      navigator.clipboard.writeText(text);
    },
    async fetchMyTransactionPages() {
      try {
        await this.$store.dispatch("transaction/pagesTransaction");
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    async fetchMyTransactions(page) {
      try {
        this.renderNow = false;
        await this.$store.dispatch("transaction/listTransactions", { page });
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.renderNow = true;
      }
    },
    async downloadReceipt(transactionId) {
      try {
        const resp = await this.$store.dispatch(
          "transaction/requestReceiptDownlaod",
          { transactionId }
        );
        const fileUrl = resp.receipt;
        window.open(fileUrl, "_blank");
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
    async showReceipt(transactionId) {
      try {
        const resp = await this.$store.dispatch(
          "transaction/requestReceiptView",
          { transactionId }
        );
        this.receiptContent = resp;
        this.showReceiptMenu = true;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <v-row no-gutters :class="classes.tabRow">
      <v-col class="d-flex align-center">
        <div
          :class="[
            classes.tabButtonStyle[tab % 2],
            classes.tabButtonSpacing,
            classes.tabButtonCommon
          ]"
          class="unselectable-text"
          @click="tab = 0"
        >
          <span style="pointer-events: none; font-weight: 500"
            >Student Subscription</span
          >
        </div>
        <div
          :class="[
            classes.tabButtonStyle[(tab + 1) % 2],
            classes.tabButtonCommon
          ]"
          class="unselectable-text"
          @click="tab = 1"
        >
          <span style="pointer-events: none; font-weight: 500"
            >SMS Subscription</span
          >
        </div>
      </v-col>
    </v-row>
    <v-sheet :class="classes.sheet">
      <v-tabs-items v-model="tab">
        <v-tab-item style="background-color: #f3f7ff">
          <student-subscription></student-subscription>
        </v-tab-item>
        <v-tab-item>
          <message-subscription></message-subscription>
        </v-tab-item>
      </v-tabs-items>
    </v-sheet>
  </div>
</template>

<script>
import StudentSubscription from "#ecf/payment-transation/components/StudentSubscription.vue";
import MessageSubscription from "#ecf/payment-transation/components/MessageSubscription.vue";

export default {
  name: "SubscriptionComponent",
  components: {
    StudentSubscription,
    MessageSubscription
  },
  data() {
    return {
      tab: 0
    };
  },
  computed: {
    screenWidth() {
      return this.$vuetify.breakpoint.width;
    },
    classes() {
      return {
        tabRow: `py-${this.breakPointValues(
          "3",
          "3",
          "3",
          "5"
        )} mt-${this.breakPointValues("2", "2", "2", "3")}`,
        tabButtonStyle: [`tab-button-selected`, `tab-button-unselected`],
        tabButtonSpacing: `mr-${this.breakPointValues("4", "4", "4", "6")}`,
        tabButtonCommon: `clickable rounded xl:text-lg lg:text-lg md:text-base sm:text-sm px-${this.breakPointValues(
          "2",
          "2",
          "2",
          "4"
        )} py-${this.breakPointValues("1", "1", "2", "2")}`,
        sheet: `sheet-${this.breakPointValues("sm", "md", "lg", "xl")}`
      };
    }
  },
  methods: {
    breakPointValues(smValue, mdValue, lgValue, xlValue) {
      let s = this.screenWidth;
      return s < 600
        ? smValue
        : s < 961
        ? mdValue
        : s < 1903
        ? lgValue
        : xlValue;
    }
  }
};
</script>

<style lang="scss" scoped>
.tab-button-unselected {
  color: $dark;
  background-color: #f3f7ff;
}

.tab-button-selected {
  color: white;
  background-color: $primary !important;
}

.sheet-sm {
  width: 100%;
}

.sheet-md {
  width: min(800px, 100%);
}

.sheet-lg {
  width: min(1024px, 100%);
}

.sheet-xl {
  width: 1024px;
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showHistoryCondition
        ? _c(
            "v-sheet",
            {
              staticClass: "mb-5 pb-5",
              class: _vm.classes.sheet,
              staticStyle: { width: "100%" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "ft-bold xl:text-lg lg:text-lg md:text-base sm:text-sm",
                },
                [_vm._v(" Active Subscriptions ")]
              ),
              !(_vm.screenWidth < 600)
                ? _c(
                    "v-row",
                    {
                      class: [
                        "mt-6 border border-solid border-default rounded",
                        _vm.classes.header.padding,
                      ],
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "v-col",
                        {
                          class: [
                            "d-flex align-center justify-start",
                            _vm.classes.header.text,
                            _vm.classes.history.firstItem,
                          ],
                          attrs: { cols: "5" },
                        },
                        [_vm._v("Title")]
                      ),
                      _c(
                        "v-col",
                        {
                          class: [
                            "d-flex align-center justify-center",
                            _vm.classes.header.text,
                          ],
                          attrs: { cols: "4" },
                        },
                        [_vm._v("Start Date")]
                      ),
                      _c(
                        "v-col",
                        {
                          class: [
                            "d-flex align-center justify-center",
                            _vm.classes.header.text,
                          ],
                          attrs: { cols: "3" },
                        },
                        [_vm._v("End Date")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "overflow-x-hidden overflow-y",
                  style: `height: ${_vm.sizes.historyContainer.height}px`,
                  attrs: { id: "hisotry-container" },
                },
                _vm._l(_vm.historyContent, function (item, index) {
                  return _c(
                    "v-row",
                    {
                      key: index,
                      class: [
                        _vm.classes.history.padding,
                        _vm.classes.history.border,
                        _vm.classes.history.margin,
                        { "primary--text": index == 0 },
                      ],
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "d-flex align-center justify-start xl:text-sm lg:text-sm md:text-xs sm:text-xs",
                          class: _vm.classes.history.firstItem,
                          attrs: { cols: _vm.cols.history.title },
                        },
                        [
                          _vm._v(
                            "Subscription for " +
                              _vm._s(item.num_students) +
                              " students"
                          ),
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          class: [
                            "d-flex align-center",
                            _vm.screenWidth < 600
                              ? "justify-start"
                              : " justify-center",
                            _vm.classes.header.text,
                            { "py-2": _vm.screenWidth < 600 },
                          ],
                          attrs: { cols: _vm.cols.history.start },
                        },
                        [
                          _vm.screenWidth < 600
                            ? _c("span", { staticClass: "pr-1" }, [
                                _vm._v("Start:"),
                              ])
                            : _vm._e(),
                          _vm._v(_vm._s(_vm.dateFormat(item.from))),
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          class: [
                            "d-flex align-center",
                            _vm.screenWidth < 600
                              ? "justify-start"
                              : " justify-center",
                            _vm.classes.header.text,
                            { "pb-2": _vm.screenWidth < 600 },
                          ],
                          attrs: { cols: _vm.cols.history.end },
                        },
                        [
                          _vm.screenWidth < 600
                            ? _c("span", { staticClass: "pr-1" }, [
                                _vm._v("End:"),
                              ])
                            : _vm._e(),
                          _vm._v(_vm._s(_vm.dateFormat(item.to))),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("submission-alert-message", {
        ref: "banner",
        staticClass: "mb-4",
        attrs: { message: _vm.bannerMessage },
        model: {
          value: _vm.showBanner,
          callback: function ($$v) {
            _vm.showBanner = $$v
          },
          expression: "showBanner",
        },
      }),
      _vm.renderNow
        ? _c(
            "v-sheet",
            { class: _vm.classes.sheet, staticStyle: { width: "100%" } },
            [
              _c("v-row", { attrs: { "no-gutters": "" } }, [
                _c(
                  "span",
                  {
                    staticClass:
                      "xl:text-sm lg:text-sm md:text-xs sm:text-xxs grey--text inert",
                  },
                  [
                    _vm._v(
                      "In this package you will getting all features unlocked. You will be charged " +
                        _vm._s(_vm.packageInfo.currency.toUpperCase()) +
                        " " +
                        _vm._s(_vm.packageInfo.price) +
                        "/per student in this package"
                    ),
                  ]
                ),
              ]),
              _c(
                "v-row",
                {
                  class: [
                    "mt-6 border border-solid border-default rounded",
                    _vm.classes.header.padding,
                  ],
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: _vm.cols.table.left } },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              class: [
                                "d-flex align-center justify-center",
                                _vm.classes.header.text,
                              ],
                              attrs: { cols: "" },
                            },
                            [_vm._v("Total Students")]
                          ),
                          _vm.screenWidth > 960
                            ? _c(
                                "v-col",
                                {
                                  class: [
                                    "d-flex align-center justify-center",
                                    _vm.classes.header.text,
                                  ],
                                  attrs: { cols: "" },
                                },
                                [_vm._v("Unit Price")]
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            {
                              class: [
                                "d-flex align-center justify-center",
                                _vm.classes.header.text,
                              ],
                              attrs: { cols: "" },
                            },
                            [_vm._v("Total Paid")]
                          ),
                          _vm.screenWidth >= 600
                            ? _c(
                                "v-col",
                                {
                                  class: [
                                    "d-flex align-center justify-center",
                                    _vm.classes.header.text,
                                  ],
                                  attrs: { cols: "" },
                                },
                                [_vm._v(_vm._s(_vm.headerNexPayment))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  !(_vm.screenWidth < 600)
                    ? _c(
                        "v-col",
                        {
                          class: [
                            "d-flex align-center justify-center",
                            _vm.classes.header.text,
                          ],
                          attrs: { cols: _vm.cols.table.right },
                        },
                        [_vm._v(" Actions ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "mt-4 d-flex align-center",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: _vm.cols.table.left } },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              class: [
                                "d-flex align-center justify-center",
                                _vm.classes.header.text,
                              ],
                              attrs: { cols: "" },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.outgoing.students,
                                    expression: "outgoing.students",
                                  },
                                ],
                                staticClass:
                                  "input-style border border-solid border-default rounded xl:text-base",
                                class: {
                                  "primary--text": _vm.canUpdateCondition,
                                },
                                attrs: { type: "text" },
                                domProps: { value: _vm.outgoing.students },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.outgoing,
                                      "students",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "input-spin-style ml-1" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "input-spin-button-style border border-solid border-default rounded d-flex align-center justify-center",
                                      on: {
                                        click: function ($event) {
                                          _vm.outgoing.students += 1
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", { attrs: { size: "12" } }, [
                                        _vm._v("mdi-plus"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "input-spin-button-style border border-solid border-default rounded d-flex align-center justify-center",
                                      on: {
                                        click: function ($event) {
                                          _vm.outgoing.students = Math.max(
                                            0,
                                            _vm.outgoing.students - 1
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", { attrs: { size: "12" } }, [
                                        _vm._v("mdi-minus"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm.screenWidth > 960
                            ? _c(
                                "v-col",
                                {
                                  class: [
                                    "d-flex align-center justify-center",
                                    _vm.classes.header.text,
                                  ],
                                  attrs: { cols: "" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.currencySymbol[
                                        _vm.packageInfo.currency
                                      ]
                                    ) +
                                      _vm._s(_vm.packageInfo.price) +
                                      "/per student"
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            {
                              class: [
                                "d-flex align-center justify-center",
                                _vm.classes.header.text,
                              ],
                              attrs: { cols: "" },
                            },
                            [_vm._v(_vm._s(_vm.totalPrice))]
                          ),
                          _vm.screenWidth >= 600
                            ? _c(
                                "v-col",
                                {
                                  class: [
                                    "d-flex align-center justify-center",
                                    _vm.classes.header.text,
                                    _vm.classes.nextPayment,
                                  ],
                                  attrs: { cols: "" },
                                },
                                [_vm._v(_vm._s(_vm.validTill))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.screenWidth >= 600
                    ? _c(
                        "v-col",
                        { attrs: { cols: _vm.cols.table.right } },
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex justify-end pr-2",
                                  attrs: { cols: "6" },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        width: "75%",
                                        depressed: "",
                                        outlined: "",
                                        color: "#0099dc",
                                        small: _vm.screenWidth <= 960,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goToSubPurchase(false)
                                        },
                                      },
                                    },
                                    [_vm._v("Update")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex justify-start pl-2",
                                  attrs: { cols: "6" },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "white--text",
                                      attrs: {
                                        width: "75%",
                                        depressed: "",
                                        color: "#0099dc",
                                        small: _vm.screenWidth <= 960,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goToSubPurchase(true)
                                        },
                                      },
                                    },
                                    [_vm._v("Pay")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.screenWidth <= 960
                ? _c(
                    "v-row",
                    { staticClass: "pt-5", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        {
                          class: [
                            "d-flex align-center py-2",
                            _vm.classes.header.text,
                          ],
                          attrs: { cols: _vm.cols.smallScreenOptions.outer },
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: _vm.cols.smallScreenOptions.inner.left
                                      .head,
                                  },
                                },
                                [_c("div", [_vm._v("Unit Price:")])]
                              ),
                              _c(
                                "v-col",
                                {
                                  class: [
                                    "d-flex",
                                    _vm.screenWidth < 600
                                      ? "justify-end"
                                      : "justify-start",
                                  ],
                                  attrs: {
                                    cols: _vm.cols.smallScreenOptions.inner.left
                                      .val,
                                  },
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.currencySymbol[
                                            _vm.packageInfo.currency
                                          ]
                                        ) +
                                        _vm._s(_vm.packageInfo.price) +
                                        "/per student "
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "py-2",
                          class: _vm.classes.header.text,
                          attrs: { cols: _vm.cols.smallScreenOptions.outer },
                        },
                        [_c("v-row", { attrs: { "no-gutters": "" } })],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.screenWidth < 600
                ? _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pr-2", attrs: { cols: "6" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                block: "",
                                depressed: "",
                                outlined: "",
                                color: "#0099dc",
                                small: _vm.smallActionButton,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.goToSubPurchase(false)
                                },
                              },
                            },
                            [_vm._v("Update")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pl-2", attrs: { cols: "6" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "white--text",
                              attrs: {
                                block: "",
                                depressed: "",
                                color: "#0099dc",
                                small: _vm.smallActionButton,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.goToSubPurchase(true)
                                },
                              },
                            },
                            [_vm._v("Pay")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-row", {
                class: _vm.classes.filler,
                attrs: { "no-gutters": "" },
              }),
            ],
            1
          )
        : _c(
            "v-sheet",
            {
              class: [_vm.classes.sheet, "d-flex align-center justify-center"],
              staticStyle: { width: "100%" },
            },
            [
              _vm.networkError
                ? _c("no-content-component", {
                    attrs: { message: "Data Unavailable" },
                  })
                : _c("v-progress-circular", {
                    attrs: { color: "primary", indeterminate: "" },
                  }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { class: _vm.classes.container },
    [
      _c(
        "v-tabs",
        {
          attrs: { "background-color": "transparent", "hide-slider": "" },
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c(
            "v-tab",
            {
              class: _vm.classes.tab.general,
              attrs: { "active-class": _vm.classes.tab.active },
            },
            [_vm._v("Paid")]
          ),
          _c(
            "v-tab",
            {
              class: _vm.classes.tab.general,
              attrs: { "active-class": _vm.classes.tab.active },
            },
            [_vm._v("Due")]
          ),
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c("v-tab-item", [_c("paid-students-table")], 1),
          _c("v-tab-item", [_c("due-students-table")], 1),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
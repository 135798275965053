var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loading.status
    ? _c(
        "v-sheet",
        {
          staticClass: "d-flex align-center justify-center",
          attrs: { height: "200" },
        },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", color: "primary" },
          }),
        ],
        1
      )
    : _c(
        "v-container",
        { class: _vm.classes.container },
        [
          _vm.pages > 0 && false
            ? _c(
                "v-row",
                {
                  staticClass: "pb-5 pt-2 justify-space-between",
                  attrs: { id: "search", "no-gutters": "" },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pb-5",
                      attrs: { cols: _vm.cols.searchBar },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.nameSearch,
                            expression: "nameSearch",
                          },
                        ],
                        staticClass: "search-style rounded rounded-r-0",
                        attrs: {
                          type: "text",
                          placeholder:
                            "Search by student, course, package name",
                        },
                        domProps: { value: _vm.nameSearch },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.nameSearch = $event.target.value
                          },
                        },
                      }),
                      _c(
                        "v-btn",
                        {
                          staticClass: "elevation-0 rounded-l-0",
                          attrs: {
                            color: "primary",
                            "min-width": "35",
                            width: "35",
                            height: "35",
                          },
                        },
                        [
                          _c("v-icon", { attrs: { color: "white" } }, [
                            _vm._v("search"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  !(_vm.screenWidth > 960) && !(_vm.screenWidth < 600)
                    ? _c(
                        "v-col",
                        {
                          staticClass: "d-flex justify-end",
                          attrs: { cols: "6" },
                        },
                        [
                          _c("v-menu", {
                            attrs: { "offset-y": "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "input",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "input-style",
                                              style: `width: ${_vm.sizes.filterBar.width}`,
                                              attrs: {
                                                type: "text",
                                                placeholder: "Filter by date",
                                                readonly: "",
                                              },
                                            },
                                            "input",
                                            attrs,
                                            false
                                          ),
                                          on
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              4011140199
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  !(_vm.screenWidth < 600)
                    ? _c(
                        "v-col",
                        {
                          staticClass:
                            "d-flex align-center justify-space-between pb-2",
                          attrs: { cols: _vm.cols.filters.outer },
                        },
                        [
                          _vm.screenWidth > 960
                            ? _c("v-menu", {
                                attrs: { "offset-y": "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "input",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "input-style",
                                                  style: `width: ${_vm.sizes.filterBar.width}`,
                                                  attrs: {
                                                    type: "text",
                                                    placeholder:
                                                      "Filter by date",
                                                    readonly: "",
                                                  },
                                                },
                                                "input",
                                                attrs,
                                                false
                                              ),
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  4011140199
                                ),
                              })
                            : _vm._e(),
                          _c("v-menu", {
                            attrs: { "offset-y": "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "input",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "input-style",
                                              style: `width: ${_vm.sizes.filterBar.width}`,
                                              attrs: {
                                                type: "text",
                                                placeholder:
                                                  "Filter by Course/Package",
                                                readonly: "",
                                              },
                                            },
                                            "input",
                                            attrs,
                                            false
                                          ),
                                          on
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              4140013147
                            ),
                          }),
                          _c("v-menu", {
                            attrs: { "offset-y": "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "input",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "input-style",
                                              style: `width: ${_vm.sizes.filterBar.width}`,
                                              attrs: {
                                                type: "text",
                                                placeholder: "Filter by batch",
                                                readonly: "",
                                              },
                                            },
                                            "input",
                                            attrs,
                                            false
                                          ),
                                          on
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1865663855
                            ),
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "d-flex align-center justify-space-between pb-2",
                      attrs: { cols: _vm.cols.action },
                    },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "input",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "input-style",
                                            staticStyle: {
                                              width: "calc(100% - 75px)",
                                            },
                                            attrs: {
                                              type: "text",
                                              readonly: "",
                                            },
                                            domProps: {
                                              value: _vm.selectedAction,
                                            },
                                          },
                                          "input",
                                          attrs,
                                          false
                                        ),
                                        on
                                      )
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1531213664
                          ),
                        },
                        [
                          _c(
                            "v-list",
                            { staticClass: "pa-0" },
                            _vm._l(_vm.actionOptions, function (action, index) {
                              return _c(
                                "v-list-item",
                                {
                                  key: index,
                                  staticStyle: { "min-height": "36px" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.dueAction(action)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "xl:text-sm lg:text-sm md:text-xs sm:text-xs",
                                    },
                                    [_vm._v(_vm._s(action))]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: { width: "70", depressed: "", color: "blue" },
                          on: { click: _vm.enforceAction },
                        },
                        [_vm._v("Save")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.pages > 0
            ? _c(
                "v-row",
                {
                  staticClass:
                    "rounded border border-solid border-default ft-bold xl:text-sm lg:text-sm md:text-xs sm:text-xs",
                  class: _vm.classes.rowHeader,
                  staticStyle: { color: "#808080" },
                  attrs: { id: "table-heading", "no-gutters": "" },
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: _vm.cols.table.left.outer } },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex align-center justify-center",
                              attrs: { cols: _vm.cols.table.left.check },
                            },
                            [
                              _c("v-checkbox", {
                                staticClass: "pa-0 ma-0",
                                attrs: { dense: "", "hide-details": "" },
                                on: { click: _vm.selectAllFunction },
                                model: {
                                  value: _vm.allSelected,
                                  callback: function ($$v) {
                                    _vm.allSelected = $$v
                                  },
                                  expression: "allSelected",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex align-center",
                              attrs: { cols: _vm.cols.table.left.name },
                            },
                            [_vm._v("Student Name")]
                          ),
                          _vm.screenWidth > 960
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "d-flex align-center",
                                  attrs: { cols: "3" },
                                },
                                [_vm._v("Course")]
                              )
                            : _vm._e(),
                          _vm.screenWidth > 960
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "d-flex align-center",
                                  attrs: { cols: "3" },
                                },
                                [_vm._v("Batch")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex align-center",
                      attrs: { cols: _vm.cols.table.right.outer },
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          !(_vm.screenWidth < 600)
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "d-flex align-center justify-center",
                                  attrs: { cols: "5" },
                                },
                                [_vm._v("Due Date")]
                              )
                            : _vm._e(),
                          !(_vm.screenWidth < 600)
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "d-flex align-center justify-center",
                                  attrs: { cols: "3" },
                                },
                                [_vm._v("Days")]
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex align-center justify-center",
                              attrs: { cols: _vm.cols.table.right.amount },
                            },
                            [_vm._v("Amount")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._l(_vm.dues, function (due, index) {
            return _c(
              "v-row",
              {
                key: index,
                staticClass:
                  "ft-medium xl:text-base lg:text-sm md:text-sm sm:text-xxs",
                class: _vm.classes.rowEntry,
                attrs: { "no-gutters": "" },
              },
              [
                _c(
                  "v-col",
                  { attrs: { cols: _vm.cols.table.left.outer } },
                  [
                    _c(
                      "v-row",
                      { attrs: { "no-gutters": "" } },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "d-flex align-center justify-center",
                            attrs: { cols: _vm.cols.table.left.check },
                          },
                          [
                            _c("v-checkbox", {
                              staticClass: "pa-0 ma-0",
                              attrs: {
                                dense: "",
                                "hide-details": "",
                                value: index,
                              },
                              model: {
                                value: _vm.selected,
                                callback: function ($$v) {
                                  _vm.selected = $$v
                                },
                                expression: "selected",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "d-flex align-center",
                            attrs: { cols: _vm.cols.table.left.name },
                          },
                          [
                            _c("div", { staticClass: "text-truncate" }, [
                              _vm._v(_vm._s(_vm.name(due))),
                            ]),
                          ]
                        ),
                        _vm.screenWidth > 960
                          ? _c(
                              "v-col",
                              {
                                staticClass: "d-flex align-center",
                                attrs: { cols: "3" },
                              },
                              [_vm._v(_vm._s(_vm.purchaseName(due)))]
                            )
                          : _vm._e(),
                        _vm.screenWidth > 960
                          ? _c(
                              "v-col",
                              {
                                staticClass: "d-flex align-center",
                                attrs: { cols: "3" },
                              },
                              [_vm._v(_vm._s(_vm.purchaseSubName(due)))]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "d-flex align-center",
                    attrs: { cols: _vm.cols.table.right.outer },
                  },
                  [
                    _c(
                      "v-row",
                      { attrs: { "no-gutters": "" } },
                      [
                        !(_vm.screenWidth < 600)
                          ? _c(
                              "v-col",
                              {
                                staticClass:
                                  "d-flex align-center justify-center",
                                attrs: { cols: "5" },
                              },
                              [_vm._v(_vm._s(_vm.dueDate(due)))]
                            )
                          : _vm._e(),
                        !(_vm.screenWidth < 600)
                          ? _c(
                              "v-col",
                              {
                                staticClass:
                                  "d-flex align-center justify-center",
                                attrs: { cols: "3" },
                              },
                              [_vm._v(_vm._s(_vm.daysBehind(due)))]
                            )
                          : _vm._e(),
                        _c(
                          "v-col",
                          {
                            staticClass: "d-flex align-center justify-center",
                            attrs: { cols: _vm.cols.table.right.amount },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.dueAmount(due)) +
                                " " +
                                _vm._s(due.currency.toUpperCase())
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                !(_vm.screenWidth > 960)
                  ? _c(
                      "v-col",
                      {
                        staticClass: "d-flex justify-center",
                        attrs: { cols: _vm.cols.table.action },
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { height: "20", icon: "" },
                            on: {
                              click: function ($event) {
                                return _vm.toggleHidden(index)
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("mdi-chevron-down")])],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-col",
                  { class: _vm.classes.rowEntryHidden, attrs: { cols: "12" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "hidden-data border-solid border-default border-0 border-t",
                        class: [
                          _vm.showHiddenCondition(index)
                            ? "hidden-data-expand"
                            : "",
                        ],
                        staticStyle: { "background-color": "#f8f8fa" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "pa-4 border-solid border-default border-0 border-b",
                            class: { "d-flex": !(_vm.screenWidth < 600) },
                          },
                          [
                            !(_vm.screenWidth < 600)
                              ? _c("div", {
                                  staticStyle: { width: "calc(100% / 12)" },
                                })
                              : _vm._e(),
                            _vm.screenWidth < 600
                              ? _c("div", { staticClass: "pb-1" }, [
                                  _vm._v(" Date: "),
                                  _c("span", { staticClass: "pl-2" }, [
                                    _vm._v(_vm._s(_vm.dueDate(due))),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.screenWidth < 600
                              ? _c("div", { staticClass: "pb-1" }, [
                                  _vm._v(" Days Behind: "),
                                  _c("span", { staticClass: "pl-2" }, [
                                    _vm._v(_vm._s(_vm.daysBehind(due))),
                                  ]),
                                ])
                              : _vm._e(),
                            _c("div", { staticClass: "pb-1" }, [
                              _vm._v(" Course: "),
                              _c("span", { staticClass: "pl-2" }, [
                                _vm._v(_vm._s(_vm.purchaseName(due))),
                              ]),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "pb-1",
                                class: { "pl-5": !(_vm.screenWidth < 600) },
                              },
                              [
                                _vm._v(" Batch: "),
                                _c("span", { staticClass: "pl-2" }, [
                                  _vm._v(_vm._s(_vm.purchaseSubName(due))),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            )
          }),
          _vm.pages == 0
            ? _c("no-content-component", {
                attrs: {
                  height: _vm.sizes.noItemBox.height,
                  message: "You have no due payments",
                },
              })
            : _vm._e(),
          _vm.pages > 0
            ? _c(
                "div",
                [
                  !(_vm.screenWidth < 600)
                    ? _c("v-pagination", {
                        class: _vm.classes.pagination,
                        attrs: {
                          length: _vm.pages,
                          "total-visible": _vm.sizes.paginator.totalVisible,
                        },
                        model: {
                          value: _vm.page,
                          callback: function ($$v) {
                            _vm.page = $$v
                          },
                          expression: "page",
                        },
                      })
                    : _vm._e(),
                  _vm.screenWidth < 600
                    ? _c(
                        "v-row",
                        { staticClass: "pt-5", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex align-center justify-end",
                              attrs: { cols: "7" },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "ft-bold sm:text-sm" },
                                [_vm._v("Page: ")]
                              ),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex align-center justify-end",
                              attrs: { cols: "5" },
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { width: "75%" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      "hide-details": "",
                                      "no-data-text": "No Due Students",
                                      items: _vm.pageItems,
                                    },
                                    model: {
                                      value: _vm.page,
                                      callback: function ($$v) {
                                        _vm.page = $$v
                                      },
                                      expression: "page",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
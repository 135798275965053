var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        { class: _vm.classes.tabRow, attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "d-flex align-center" }, [
            _c(
              "div",
              {
                staticClass: "unselectable-text",
                class: [
                  _vm.classes.tabButtonStyle[_vm.tab % 2],
                  _vm.classes.tabButtonSpacing,
                  _vm.classes.tabButtonCommon,
                ],
                on: {
                  click: function ($event) {
                    _vm.tab = 0
                  },
                },
              },
              [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "pointer-events": "none",
                      "font-weight": "500",
                    },
                  },
                  [_vm._v("Student Subscription")]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "unselectable-text",
                class: [
                  _vm.classes.tabButtonStyle[(_vm.tab + 1) % 2],
                  _vm.classes.tabButtonCommon,
                ],
                on: {
                  click: function ($event) {
                    _vm.tab = 1
                  },
                },
              },
              [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "pointer-events": "none",
                      "font-weight": "500",
                    },
                  },
                  [_vm._v("SMS Subscription")]
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-sheet",
        { class: _vm.classes.sheet },
        [
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c(
                "v-tab-item",
                { staticStyle: { "background-color": "#f3f7ff" } },
                [_c("student-subscription")],
                1
              ),
              _c("v-tab-item", [_c("message-subscription")], 1),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }